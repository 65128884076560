import React, { Fragment } from 'react';
import { Link } from 'gatsby';

import Styled from 'styled-components';

import Logo from '../images/alpha-logo.png';
import Modal from '../primespot-ui/components/modal';
import LoginForm from './loginForm';
import Feedback from './feedback';
import Toggle from '../primespot-ui/components/toggle';
import Notifications from './notificationWidget';

const Header = ({ user, login, getEmailToken, logout, growl }) => {
  return (
    <Toggle>
      {({ toggled: loginFormToggled, toggle: toggleLoginForm }) => (
        <Toggle>
          {({ toggled: feedbackModalToggled, toggle: toggleFeedbackModal }) => (
            <Fragment>
              <Wrapper>
                <Link to="/backend">
                  <img src={Logo} alt="Sci-Tech Carpet Cleaning" />
                </Link>

                <div className="header__right-side">
                  <span style={{ marginRight: '24px' }}>
                    <Notifications />
                  </span>
                  {user ? (
                    <button onClick={toggleFeedbackModal}>Feedback</button>
                  ) : (
                    <button onClick={toggleLoginForm}>Login</button>
                  )}

                  {loginFormToggled && (
                    <Modal onClose={toggleLoginForm}>
                      <LoginForm
                        login={login}
                        getEmailToken={getEmailToken}
                        loginCompleted={toggleLoginForm}
                        growl={growl}
                      />
                    </Modal>
                  )}
                </div>
              </Wrapper>
              <Feedback
                visible={feedbackModalToggled}
                onClose={toggleFeedbackModal}
              />
            </Fragment>
          )}
        </Toggle>
      )}
    </Toggle>
  );
};

const Wrapper = Styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  height: 50px;
  background-color: #FAFAFA;
  background-color: #FFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  box-sizing: border-box;
  z-index: 10;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, .15);

  .header__right-side {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 100%;
    align-items: center;
  }

  button {
    height: 100%;
    color: white;
    background-color: #209CEE;
    padding-left: 24px;
    padding-right: 24px;
    border: none;
    cursor: pointer;
    transition: all .4s ease;
    outline: none;
    font-size: 16px;

    &:hover {
      background-color: #30ACFF;
    }
  }
`;

export default Header;
