import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import {
  login,
  getEmailToken,
} from '../redux/actions/authentication/actionCreators';

import { growl } from '../redux/actions/notifications/actionCreators';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import Styled, { ThemeProvider } from 'styled-components';

import theme from '../styles/theme';
import ResetPasswordModal from './resetPasswordModal';
import Toggle from '../primespot-ui/components/toggle';

class LoginForm extends Component {
  state = {
    mode: 'password',
  };

  static propTypes = {
    login: PropTypes.func,
    isLoading: PropTypes.bool,
    getEmailToken: PropTypes.func,
    loginCompleted: PropTypes.func,
    growl: PropTypes.func,
  };

  static defaultProps = {
    login: () => {},
    isLoading: false,
    getEmailToken: () => {},
    loginCompleted: () => {},
    growl: () => {},
  };

  render() {
    const {
      login,
      isLoading,
      getEmailToken,
      loginCompleted,
      growl,
    } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <LoginFormWrapper bgColor="#FFF">
          <Formik
            enableReinitialize
            initialValues={{
              loginEmail: '',
              loginPassword: '',
              loginToken: '',
            }}
            validationSchema={Yup.object().shape({
              loginEmail: Yup.string()
                .email('A valid email is required.')
                .required('Email address is required.'),
              loginPassword: Yup.string().min(
                8,
                'Password must be at least eight characters long.'
              ),
            })}
            onSubmit={(values, { setSubmitting }) => {
              login({
                email: values.loginEmail,
                password:
                  this.state.mode === 'password'
                    ? values.loginPassword
                    : undefined,
                token:
                  this.state.mode === 'token' ? values.loginToken : undefined,
              })
                .then(res => {
                  if (!res) {
                    growl({
                      message: 'Login failed.  Please try again.',
                      type: 'danger',
                    });
                    console.error('Login failed');
                  } else {
                    growl({
                      message: 'You have been logged in.',
                      type: 'success',
                    });
                    if (loginCompleted) {
                      loginCompleted();
                    }
                  }
                })
                .catch(err => {
                  growl({
                    message: 'Login failed.  Please try again.',
                    type: 'danger',
                  });
                  console.error('An error occurred.', err);
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            {({ isSubmitting, values, errors, handleSubmit, submitForm }) => (
              <Form>
                <h2>Login using the form below</h2>
                <div>
                  <label htmlFor="loginEmail">Email</label>
                  <Field
                    id="loginEmail"
                    name="loginEmail"
                    type="email"
                    placeholder="john.smith@email.com"
                    component="input"
                    onKeyDown={ev => {
                      if (ev.keyCode === 13) {
                        ev.preventDefault();
                        handleSubmit();
                      }
                    }}
                  />
                  <ErrorMessage
                    name="loginEmail"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div>
                  {this.state.mode === 'password' && (
                    <Fragment>
                      <label htmlFor="loginPassword">Password</label>
                      <Field
                        id="loginPassword"
                        name="loginPassword"
                        type="password"
                        placeholder="password"
                        component="input"
                        onKeyDown={ev => {
                          if (ev.keyCode === 13) {
                            ev.preventDefault();
                            handleSubmit();
                          }
                        }}
                      />
                      <ErrorMessage
                        name="loginPassword"
                        component="div"
                        className="error-message"
                      />
                      <Toggle>
                        {({ toggle, toggled }) => (
                          <Fragment>
                            <a
                              href="#forgot-password"
                              style={{ marginTop: '12px' }}
                              onClick={ev => {
                                ev.preventDefault();

                                toggle();
                              }}
                            >
                              Forgot password?
                            </a>
                            <ResetPasswordModal
                              onClose={toggle}
                              visible={toggled}
                              growl={growl}
                            />
                          </Fragment>
                        )}
                      </Toggle>
                    </Fragment>
                  )}
                  {this.state.mode === 'token' && (
                    <Fragment>
                      <label htmlFor="loginToken">Token</label>
                      <Field
                        id="loginToken"
                        name="loginToken"
                        type="password"
                        placeholder="token"
                        component="input"
                      />
                      <ErrorMessage
                        name="loginToken"
                        component="div"
                        className="error-message"
                      />
                    </Fragment>
                  )}
                </div>
                <button
                  type="submit"
                  disabled={isSubmitting || isLoading === true}
                >
                  {isLoading ? 'Please wait...' : 'Login'}
                </button>
                {values.loginEmail &&
                  !errors.loginEmail &&
                  this.state.mode !== 'token' && (
                    <a
                      className="login-form__email-token-button"
                      href="#get-email-token"
                      onClick={ev => {
                        ev.preventDefault();

                        getEmailToken({ email: values.loginEmail })
                          .then(res => {
                            if (res.status !== 201) {
                              growl({
                                message:
                                  'Token creation failed.  Please try again.',
                                type: 'danger',
                              });
                            } else {
                              this.setState({ mode: 'token' });
                            }
                          })
                          .catch(err => {
                            growl({
                              message:
                                'Token creation failed.  Please try again.',
                              type: 'danger',
                            });
                            console.error(err);
                          });
                      }}
                    >
                      {isLoading ? 'Please wait...' : 'Get email token'}
                    </a>
                  )}
              </Form>
            )}
          </Formik>
        </LoginFormWrapper>
      </ThemeProvider>
    );
  }
}

const LoginFormWrapper = Styled.div`
  background-color: ${({ bgColor }) => (bgColor ? bgColor : '#F6F2F2')};
  padding: 12px 24px;
  border-radius: 4px;
  margin: 48px auto;
  box-sizing: border-box;

  a {
    color: ${({ theme }) => theme.primary.colors.darkBlue.base};
    transition: all .3s ease;
    text-decoration: none;
    display: inline-block;

    &:hover {
      color: ${({ theme }) => theme.primary.colors.lightBlue.base};
    }
  }

  h2 {
    margin-top: 0;
  }

  div {
    margin-bottom: 24px;
  }

  label {
    margin-bottom: 12px;
    display: block;
  }

  .error-message {
    color: #FF3860;
    margin-top: 6px;
  }

  input {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    display: block;
    border: none;
    padding-left: 12px;
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.3);
  }

  select {
    background-color: #FFF;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    display: block;
    border: none;
    padding-left: 12px;
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.3);
  }

  button:not(.button__secondary) {
    background: #01D1B2;
    padding: 12px 18px;
    border: none;
    color: white;
    font-size: 24px;
    border-radius: 2px;
    transition: all .4s ease-in-out;
    cursor: pointer;
    margin-right: 24px;
    margin-bottom: 24px;

    &:hover {
      background: #11E1D2;
    }
  }

  button.button__secondary {
    border: none;
    cursor: pointer;
    color: #333;
    transition: all .4s ease-in-out;

    &:after {
      content: '';
      position: relative;
      height: 4px;
      width: 100%;
      background-color: #01D1B2;
      display: block;
      top: 6px;
      opacity: 0;
      transition: all .2s ease-in-out;
    }

    &:hover:after {
      opacity: 1;
    }
  }

  .login-form__email-token-button {
    display: inline-block;
    text-decoration: none;
    padding: 12px 18px;
    font-size: 24px;
    border-radius: 2px;
    transition: all .4s ease-in-out;
    cursor: pointer;
    background-color: ${({ theme }) => theme.primary.colors.teal.soft};
    color: ${({ theme }) => theme.primary.colors.text.teal.darkest};

    &:active, &:hover {
      background-color: ${({ theme }) => theme.primary.colors.teal.lightest};
    }
  }
`;

const mapStateToProps = state => {
  return {
    isLoading: state.authentication.isLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: ({ email, password, token }) =>
      dispatch(login({ email, password, token })),
    getEmailToken: () => dispatch(getEmailToken()),
    growl: ({ message, type }) => dispatch(growl({ message, type })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
