import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Styled from 'styled-components';

import SVG from './svg';

class Accordion extends Component {
  constructor(props) {
    super(props);

    this.contentRef = React.createRef();
  }

  static propTypes = {
    activator: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  };

  state = {
    open: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.open &&
      prevState.open &&
      this.props.children !== prevProps.children
    ) {
      this.contentRef.current.style.maxHeight =
        this.contentRef.current.scrollHeight + 'px';
    }
  }

  handleClick = () => {
    this.setState(
      prevState => ({ open: !prevState.open }),
      () => {
        if (!this.state.open) {
          this.contentRef.current.style.maxHeight = null;
        } else {
          this.contentRef.current.style.maxHeight =
            this.contentRef.current.scrollHeight + 'px';
        }
      }
    );
  };

  render() {
    const { activator, children } = this.props;
    return (
      <Wrapper open={this.state.open}>
        <div onClick={this.handleClick} className="accordion__activator">
          <span className="accordion__arrow-icon">
            <SVG image="cheveron-down" />
          </span>{' '}
          <span>{activator}</span>
        </div>
        <div className="accordion__content" ref={this.contentRef}>
          {children}
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = Styled.div`
  margin-bottom: 12px;

  .accordion__activator {
    cursor: pointer;
    margin-bottom: 12px;
    display: flex;
    align-items: center;

    & > span {
      display: inline-flex;
      align-items: center;
    }
  }

  .accordion__arrow-icon {
    margin-right: 6px;

    .svg__icon {
      width: 16px;
      height: 16px;
      fill: #333;
      transition: all .3s ease;
      transform: ${({ open }) => (open === true ? 'none' : 'rotate(-90deg)')};
    }
  }

  .accordion__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    padding: 0 20px;
    box-sizing: border-box;
    margin-bottom: ${({ open }) => (open === true ? '24px' : '0')};
  }
`;

export default Accordion;
