import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  login,
  logout,
  tokenExists,
  getEmailToken,
} from '../redux/actions/authentication/actionCreators';

import { growl } from '../redux/actions/notifications/actionCreators';

import {
  allow,
  isAllowed,
} from '../redux/actions/authorization/actionCreators';

import classnames from 'classnames';
import axios from 'axios';
import io from 'socket.io-client';

import Styled, { ThemeProvider } from 'styled-components';
import theme from '../styles/theme';

import Gravatar from '../primespot-ui/components/gravatar';
import SVG from '../primespot-ui/components/svg';
import Toggle from '../primespot-ui/components/toggle';
import Modal from '../primespot-ui/components/modal';
import IsIdle from '../primespot-ui/systems/isIdle';
import UserCard from './userCard';
import SmartForm from './smartForm';

import config from '../config';

const usersSchema = [
  {
    name: 'firstName',
    dataType: 'string',
    id: 'users-edit__first-name',
    label: 'First name',
    type: 'text',
    placeholder: 'John',
  },
  {
    name: 'lastName',
    dataType: 'string',
    id: 'users-edit__last-name',
    label: 'Last name',
    type: 'text',
    placeholder: 'Smith',
  },
  {
    name: 'email',
    dataType: 'string',
    id: 'users-edit__email',
    label: 'Email address',
    type: 'email',
    placeholder: 'john.smith@email.com',
  },
  {
    name: 'password',
    dataType: 'string',
    label: 'Password',
    type: 'password',
    placeholder: 'password',
    availableOnEdit: false,
  },
  {
    name: 'phoneNumber',
    dataType: 'string',
    label: 'Phone number',
    type: 'text',
    placeholder: '540-555-1234',
  },
  {
    name: 'role',
    dataType: 'select',
    id: 'users-edit__role',
    label: 'Role',
    placeholder: 'Role',
    availableOnCreate: false,
    options: [
      {
        value: 'user',
        label: 'User',
        selected: true,
      },
      {
        value: 'sales',
        label: 'Sales',
      },
      {
        value: 'admin',
        label: 'Admin',
      },
    ],
  },
];

class UserSidebar extends Component {
  state = {
    socketConnected: false,
    open: false,
    users: [],
    selectedUser: null,
  };

  static propTypes = {
    // Whether or not the widget is open by default.
    defaultOpen: PropTypes.bool,
  };

  static defaultProps = {
    defaultOpen: false,
  };

  componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.setState({ open: this.props.defaultOpen });

    this.presenceSocket = io(`${config.backendServer}/presence`);

    this.presenceSocket.emit(
      'presence.subscribe',
      {
        token: this.props.getToken(),
      },
      err => {
        if (err) {
          console.error(err);
        }
      }
    );

    this.presenceSocket.emit(
      'presence.status.online',
      {
        token: this.props.getToken(),
      },
      err => {
        if (err) {
          console.error(err);
        }
      }
    );

    axios
      .get(`${config.backendServer}/users`, {
        params: {
          anonymous: false,
        },
        headers: {
          authorization: `Token ${this.props.getToken()}`,
        },
      })
      .then(res => {
        this.presenceSocket.emit(
          'presence.get.online',
          {
            token: this.props.getToken(),
          },
          (err, onlineUsers) => {
            if (err) {
              console.error(err);
            } else {
              const data = res.data.results.map(user => ({
                ...user,
                status:
                  onlineUsers.filter(u => u.user === user._id).length > 0
                    ? 'online'
                    : 'offline',
              }));
              this._isMounted && this.setState({ users: data });
            }
          }
        );
      })
      .catch(err => {
        console.group('[UserSidebar] GET users');
        console.error(err);
        console.groupEnd();
      });

    this.presenceSocket.on('connect', () => {
      this._isMounted && this.setState({ socketConnected: true });
    });

    this.presenceSocket.on('disconnect', () => {
      this._isMounted && this.setState({ socketConnected: false });
    });

    this.presenceSocket.on('presence.change.online', data => {
      const newUsers = this.state.users.map(user => {
        if (data.user !== user._id) {
          return user;
        } else {
          return Object.assign({}, user, { status: data.status });
        }
      });

      this._isMounted && this.setState({ users: newUsers });

      if (
        this.state.selectedUser &&
        this.state.selectedUser._id === data.user
      ) {
        this._isMounted &&
          this.setState({
            selectedUser: Object.assign({}, this.state.selectedUser, {
              status: data.status,
            }),
          });
      }
    });

    this.presenceSocket.on('presence.change.idle', data => {
      const newUsers = this.state.users.map(user => {
        if (user._id !== data.user) {
          return user;
        } else {
          return Object.assign({}, user, { status: data.status });
        }
      });
      this._isMounted && this.setState({ users: newUsers });

      if (
        this.state.selectedUser &&
        this.state.selectedUser._id === data.user
      ) {
        this._isMounted &&
          this.setState({
            selectedUser: Object.assign({}, this.state.selectedUser, {
              status: data.status,
            }),
          });
      }
    });

    this.presenceSocket.on('presence.change.offline', data => {
      const newUsers = this.state.users.map(user => {
        if (data.user !== user._id) {
          return user;
        } else {
          return Object.assign({}, user, { status: data.status });
        }
      });

      this._isMounted && this.setState({ users: newUsers });

      if (
        this.state.selectedUser &&
        this.state.selectedUser._id === data.user
      ) {
        this._isMounted &&
          this.setState({
            selectedUser: Object.assign({}, this.state.selectedUser, {
              status: data.status,
            }),
          });
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.presenceSocket.off('presence.change.online');
    this.presenceSocket.off('presence.change.offline');
    this.presenceSocket.disconnect();
  }

  idleStatusChange = status => {
    if (status === true) {
      this.presenceSocket.emit(
        'presence.status.idle',
        {
          token: this.props.getToken(),
        },
        err => {
          if (err) {
            console.error(err);
          }
        }
      );
    } else {
      this.presenceSocket.emit(
        'presence.status.online',
        {
          token: this.props.getToken(),
        },
        err => {
          console.error(err);
        }
      );
    }
  };

  render() {
    const classes = classnames({
      'user-sidebar-wrapper': true,
      'is-open': this.state.open,
    });

    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <SidebarWrapper open={this.state.open} className={classes}>
            {this.state.socketConnected ? (
              <Fragment>
                {this.props.user.role === 'admin' && (
                  <Toggle>
                    {({ toggle, toggled }) => (
                      <Fragment>
                        <span
                          className="user-sidebar__add-user-button"
                          onClick={toggle}
                        >
                          <SVG image="compose" />
                        </span>
                        {toggled && (
                          <Modal onClose={toggle} header="Add User">
                            <SmartForm
                              schema={usersSchema}
                              apiUrl={`${config.backendServer}/users`}
                              headers={{
                                authorization: `Token ${this.props.getToken()}`,
                              }}
                              notify={this.props.growl}
                              enableSave={this.props.isAllowed(
                                this.props.user.role,
                                'users',
                                'update'
                              )}
                            />
                          </Modal>
                        )}
                      </Fragment>
                    )}
                  </Toggle>
                )}
                <span
                  className="user-sidebar__close-button"
                  onClick={() => {
                    this.setState({ open: false });
                  }}
                >
                  &times;
                </span>
                <span className="user-sidebar__header">Users</span>
                <ul>
                  {this.state.users
                    .sort((a, b) => {
                      const map = {
                        online: 0,
                        offline: 1,
                      };
                      return map[a.status] - map[b.status];
                    })
                    .map(user => (
                      <li
                        key={user._id}
                        onClick={() => {
                          this.setState({ selectedUser: user });
                        }}
                      >
                        <span className="user-sidebar__image">
                          <Gravatar
                            email={user.email || 'random@email.com'}
                            size={30}
                            style={{
                              borderRadius: '50%',
                              overflow: 'hidden',
                              marginRight: '6px',
                            }}
                          />
                        </span>
                        <span className="user-sidebar__name">{`${
                          user.firstName
                        } ${user.lastName}`}</span>
                        <span
                          className={`user-sidebar__status ${
                            user.status === 'online' ? 'online' : ''
                          }${user.status === 'idle' ? 'idle' : ''}`}
                        />
                      </li>
                    ))}
                </ul>
              </Fragment>
            ) : (
              <span className="user-sidebar__header">Loading...</span>
            )}
          </SidebarWrapper>
          {this.state.selectedUser && (
            <UserCard
              user={this.state.selectedUser}
              onClose={() => {
                this.setState({ selectedUser: null });
              }}
            />
          )}
          <WidgetWrapper
            open={this.state.open}
            onClick={() => {
              this.setState({ open: true });
            }}
          >
            {this.state.socketConnected ? (
              <Fragment>
                <span>Users</span>
                <span>
                  {`( ${
                    this.state.users.filter(u => u.status !== 'offline').length
                  } )`}
                </span>
              </Fragment>
            ) : (
              <span>Loading...</span>
            )}
          </WidgetWrapper>
          <IsIdle
            timeout={5 * 60 * 1000}
            onIdleStatusChange={this.idleStatusChange}
          />
        </Fragment>
      </ThemeProvider>
    );
  }
}

const SidebarWrapper = Styled.div`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  height: calc(100vh - 52px);
  background-color: ${({ theme }) => theme.primary.colors.lightBlue.base};
  background-color: #F2F4F8;
  border-left: 1px solid rgba(0, 0, 0, .1);
  box-sizing: border-box;
  position: fixed;
  top: 52px;
  right: 0;
  bottom: 0;
  z-index: 100;
  padding: 24px 12px;
  width: 190px;
  flex-direction: column;

  .user-sidebar__add-user-button {
    width: 12px;
    height: 12px;
    position: absolute;
    top: 7px;
    right: 36px;
    fill: ${({ theme }) => theme.primary.colors.gray.darkest};
    cursor: pointer;
    transition: all .3s ease;

    &:hover {
      fill: ${({ theme }) => theme.primary.colors.gray.base};
    }
  }

  .user-sidebar__close-button {
    position: absolute;
    top: 3px;
    right: 12px;
    cursor: pointer;
    font-size: 24px;
    transition: all .3s ease;
    color: ${({ theme }) => theme.primary.colors.gray.darkest};
  }

  .user-sidebar__close-button:hover {
    color: ${({ theme }) => theme.primary.colors.gray.base};
  }

  .user-sidebar__header {
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-size: 12px;
    margin: 12px 0;
    display: block;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }

  li {
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    span.user-sidebar__image {

    }

    span.user-sidebar__name {
      width: 115px;
      overflow: hidden;
      font-size: 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    span.user-sidebar__status {
      width: 6px;
      height: 6px;
      display: inline-block;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.primary.colors.gray.base};
    }

    span.user-sidebar__status.online {
      background-color: ${({ theme }) => theme.primary.colors.green.base};
    }

    span.user-sidebar__status.idle {
      background-color: ${({ theme }) => theme.primary.colors.yellow.base};
    }
  }
`;

const WidgetWrapper = Styled.div`
  display: ${({ open }) => (open ? 'none' : 'flex')};
  flex-direction: row;
  position: fixed;
  bottom: 0;
  right: 18px;
  background: ${({ theme }) => theme.primary.colors.lightBlue.base};
  height: 36px;
  width: 120px;
  color: white;
  justify-content: space-evenly;
  border-radius: 2px 2px 0 0;
  box-sizing: border-box;
  align-items: center;
  z-index: 100;
  cursor: pointer;
  font-size: 16px;
`;

const mapStateToProps = state => {
  return {
    user: state.authentication.user,
    token: state.authentication.token,
    getToken: () => state.authentication.token,
    isLoading: state.authentication.isLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: ({ email, password, token }) =>
      dispatch(login({ email, password, token })),
    logout: () => dispatch(logout()),
    tokenExists: () => dispatch(tokenExists()),
    getEmailToken: () => dispatch(getEmailToken()),
    growl: ({ message, type }) => dispatch(growl({ message, type })),
    allow: (roles, resources, actions) =>
      dispatch(allow(roles, resources, actions)),
    isAllowed: (role, resources, actions) =>
      dispatch(isAllowed(role, resources, actions)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSidebar);
