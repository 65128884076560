import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { growl } from '../../redux/actions/notifications/actionCreators';

import axios from 'axios';

import Styled from 'styled-components';

import SlidingModal from '../../primespot-ui/components/slidingModal';
import SVG from '../../primespot-ui/components/svg';
import {
  BigForm,
  Page,
  Textarea,
  OptionGroup,
  OptionButton,
} from '../../primespot-ui/BigForm';

import config from '../../config';

const Feedback = ({ visible, onClose, user }) => {
  return (
    <Wrapper>
      <SlidingModal header="Send Feedback" visible={visible} onClose={onClose}>
        <BigForm
          pages={[<FeedbackTypePage />, <FeedbackPage />, <ExtraPage />]}
          initialValues={{
            feedbackType: '',
            feedback: '',
            easeOfUse: '',
            effectiveness: '',
          }}
          onSubmit={result => {
            axios
              .post(`${config.backendServer}/email`, {
                email: user.email,
                name: `${user.firstName} ${user.lastName}`,
                toEmail: 'webdev',
                subject: 'Feedback from Sci-Tech backend',
                blackbox: result.values,
              })
              .then(() => {
                onClose();
                growl({
                  type: 'success',
                  message: 'Thank you for your feedback.',
                });
              })
              .catch(err => {
                console.error(err);
                growl({
                  type: 'danger',
                  message:
                    'There was a problem submitting your feedback.  Please try again.',
                });
              });
          }}
        />
      </SlidingModal>
    </Wrapper>
  );
};

Feedback.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.node,
};

Feedback.defaultProps = {
  visible: false,
  children: <div />,
};

const FeedbackTypePage = props => {
  return (
    <Page {...props}>
      {({ values, onChange }) => (
        <OptionGroup
          name="feedbackType"
          value={values.feedbackType}
          onChange={onChange}
        >
          <h2>What type of feedback are you sending?</h2>
          <OptionButton value="bug">Bug report</OptionButton>
          <OptionButton value="feature">Feature request</OptionButton>
          <OptionButton value="other">Other</OptionButton>
        </OptionGroup>
      )}
    </Page>
  );
};

const FeedbackPage = props => {
  return (
    <Page {...props}>
      {({ values, onChange }) => (
        <Fragment>
          <Textarea
            label="What is your feedback?"
            name="feedback"
            value={values.feedback}
            onChange={onChange}
          />
        </Fragment>
      )}
    </Page>
  );
};

const ExtraPage = props => {
  return (
    <Page {...props}>
      {({ values, onChange }) => (
        <Fragment>
          <h2>Please answer these other questions.</h2>
          <p>How would you rate the ease of use of this application?</p>
          <OptionGroup
            name="easeOfUse"
            value={values.easeOfUse}
            onChange={onChange}
          >
            <OptionButton value="one">
              <OneStar />
            </OptionButton>
            <OptionButton value="two">
              <TwoStar />
            </OptionButton>
            <OptionButton value="three">
              <ThreeStar />
            </OptionButton>
            <OptionButton value="four">
              <FourStar />
            </OptionButton>
            <OptionButton value="five">
              <FiveStar />
            </OptionButton>
          </OptionGroup>
          <p>How well do you feel that this application serves your needs?</p>
          <OptionGroup
            name="effectiveness"
            value={values.effectiveness}
            onChange={onChange}
          >
            <OptionButton value="one">
              <OneStar />
            </OptionButton>
            <OptionButton value="two">
              <TwoStar />
            </OptionButton>
            <OptionButton value="three">
              <ThreeStar />
            </OptionButton>
            <OptionButton value="four">
              <FourStar />
            </OptionButton>
            <OptionButton value="five">
              <FiveStar />
            </OptionButton>
          </OptionGroup>
        </Fragment>
      )}
    </Page>
  );
};

const OneStar = () => {
  return (
    <Fragment>
      <SVG image="star-full" />
    </Fragment>
  );
};

const TwoStar = () => {
  return (
    <Fragment>
      <SVG image="star-full" />
      <SVG image="star-full" />
    </Fragment>
  );
};

const ThreeStar = () => {
  return (
    <Fragment>
      <SVG image="star-full" />
      <SVG image="star-full" />
      <SVG image="star-full" />
    </Fragment>
  );
};

const FourStar = () => {
  return (
    <Fragment>
      <SVG image="star-full" />
      <SVG image="star-full" />
      <SVG image="star-full" />
      <SVG image="star-full" />
    </Fragment>
  );
};

const FiveStar = () => {
  return (
    <Fragment>
      <SVG image="star-full" />
      <SVG image="star-full" />
      <SVG image="star-full" />
      <SVG image="star-full" />
      <SVG image="star-full" />
    </Fragment>
  );
};

const Wrapper = Styled.div`
  h2 {
    margin-top: 0;
  }

  .svg__star-full {
    fill: #333;
    width: 12px;
    height: 12px;
    pointer-events: none;
  }
`;

const mapStateToProps = state => {
  return {
    user: state.authentication.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    growl: ({ type, message }) => dispatch(growl({ type, message })),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Feedback);
