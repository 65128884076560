import React, { Component, Fragment } from 'react';

import Styled from 'styled-components';
import axios from 'axios';

import SlidingModal from '../primespot-ui/components/slidingModal';

import { TextInput, PrimaryButton } from '../styles/forms';

import config from '../config';

class ResetPasswordModal extends Component {
  state = {
    step: 'getToken',
    emailAddressInput: '',
  };

  componentDidMount() {
    // if (this.props.user) {
    //   this.setState({ step: 'changePassword' });
    // } else {
    //   this.setState({ step: 'getToken' });
    // }
  }

  render() {
    const { onClose, visible, growl } = this.props;
    return (
      <Wrapper>
        <SlidingModal
          visible={visible}
          onClose={onClose}
          header={<h3>Reset password</h3>}
        >
          <div className="forgot-password-modal__content">
            {this.state.step === 'getToken' && (
              <Fragment>
                <label>
                  <span>Enter your email address</span>
                  <TextInput
                    type="email"
                    value={this.state.emailAddressInput}
                    onChange={ev => {
                      const value = ev.target.value;
                      this.setState({ emailAddressInput: value });
                    }}
                  />
                </label>
                <PrimaryButton
                  onClick={() => {
                    axios
                      .post(`${config.backendServer}/users/reset-token`, {
                        email: this.state.emailAddressInput,
                      })
                      .then(res => {
                        if (res.status === 201) {
                          this.setState({ step: 'changePassword' });
                        }
                      })
                      .catch(err => {
                        console.error(err);
                      });
                  }}
                >
                  Get token
                </PrimaryButton>
              </Fragment>
            )}
            {this.state.step === 'changePassword' && (
              <Fragment>
                <p>
                  We have sent you a password reset token to your email address.
                  Copy the token below.
                </p>
                <label>
                  <span>Reset password token</span>
                  <TextInput
                    id="password-reset__reset-token"
                    name="password-reset__reset-token"
                    type="password"
                  />
                </label>
                <label>
                  <span>New password</span>
                  <TextInput
                    id="password-reset__new-password"
                    name="password-reset__new-password"
                    type="password"
                  />
                </label>
                <label>
                  <span>Repeat new password</span>
                  <TextInput
                    id="password-reset__new-password-2"
                    name="password-reset__new-password-2"
                    type="password"
                  />
                </label>
                <PrimaryButton
                  onClick={() => {
                    const resetToken = document.querySelector(
                      '#password-reset__reset-token'
                    );
                    const newPassword = document.querySelector(
                      '#password-reset__new-password'
                    );
                    const newPassword2 = document.querySelector(
                      '#password-reset__new-password-2'
                    );

                    if (newPassword.value !== newPassword2.value) {
                      growl({
                        type: 'danger',
                        message: `Repeated password doesn't match new password.`,
                      });
                      newPassword.value = '';
                      newPassword2.value = '';
                      return;
                    }

                    axios
                      .post(`${config.backendServer}/users/password-reset`, {
                        email: this.state.emailAddressInput,
                        token: resetToken.value,
                        password: newPassword.value,
                      })
                      .catch(err => {
                        console.error(err);
                      });
                  }}
                >
                  Change password
                </PrimaryButton>
              </Fragment>
            )}
          </div>
        </SlidingModal>
      </Wrapper>
    );
  }
}

const Wrapper = Styled.div`
  h3 {
    margin-top: 0;
  }

  .forgot-password-modal__content {
    label {
      display: block;
    }

    label span {
      display: block;
    }
  }
`;

export default ResetPasswordModal;
