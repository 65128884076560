import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class ClickTracker extends Component {
  state = {
    clicks: [],
  };

  static propTypes = {
    // Callback fired when click occurs.
    onClick: PropTypes.func,
    children: PropTypes.func,
  };

  static defaultProps = {
    children: () => {},
  };

  componentDidMount() {
    window.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClick);
  }

  handleClick = ev => {
    const clicks = this.state.clicks;
    const newClick = {
      event: ev,
      time: new Date(),
    };
    clicks.push(newClick);
    this.setState({ clicks }, () => {
      this.props.onClick &&
        this.props.onClick({
          recent: newClick,
          all: clicks,
        });
    });
  };

  render() {
    return (
      <Fragment>
        {this.props.children({
          clicks: this.state.clicks,
        })}
      </Fragment>
    );
  }
}

export default ClickTracker;
