import React from 'react';
import PropTypes from 'prop-types';

import Styled from 'styled-components';

const Textbox = ({ label, type, placeholder, style, icon, ...rest }) => {
  function displayIcon(icon) {
    if (typeof icon === 'string') {
      return (
        <span className="primespot-ui__big-form__textbox__icon">
          <img src={icon} alt="" />
        </span>
      );
    } else {
      return (
        <span className="primespot-ui__big-form__textbox__icon">{icon}</span>
      );
    }
  }

  return (
    <Label
      className="primespot-ui__big-form__textbox"
      style={style}
      icon={icon}
    >
      <div className="primespot-ui__big-form__textbox__label">{label}</div>
      <div className="primespot-ui__big-form__textbox__inner-wrapper">
        <input
          className="primespot-ui__big-form__textbox__input"
          type={type}
          placeholder={placeholder}
          {...rest}
        />
        {icon && displayIcon(icon)}
      </div>
    </Label>
  );
};

Textbox.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Textbox.defaultProps = {
  label: '',
  type: 'text',
  placeholder: '',
  style: {},
};

const Label = Styled.label`
  position: relative;
  display: inline-block;
  margin-right: 24px;
  margin-bottom: 24px;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  .primespot-ui__big-form__textbox__label {
    margin-bottom: 6px;
  }

  input {
    padding: 6px;
    padding-right: ${({ icon }) => (icon ? '30px' : '6px')};
    border-radius: 4px;
    border: 1px solid rgba(200, 200, 200, .7);
    width: 280px;
  }

  .primespot-ui__big-form__textbox__inner-wrapper {
    position: relative;
    display: inline;
  }

  .primespot-ui__big-form__textbox__icon {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    max-width: 18px;
    max-height: 18px;
    fill: #777;
    pointer-events: none;

    * {
      width: 18px;
      max-height: 18px;
    }
  }
`;

export default Textbox;
