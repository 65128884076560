import React from 'react';

import Styled from 'styled-components';

const TextareaInput = Styled.textarea`
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, .25);
  border: none;
  resize: none;
  min-height: 90px;
  margin: 12px 12px 12px 0;
`;

const PrimaryButton = Styled.button`
  padding: 12px 18px;
  background-color: #01d1b2;
  color: #FFF;
  border: none;
  border-radius: 2px;
  margin: 12px 12px 12px 0;
  transition: all .3s ease;
  cursor: pointer;

  &:hover {
    background-color: #21F1D2;
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
`;

const TextInput = Styled.input`
  border-radius: 4px;
  border: none;
  box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.15);
  padding: 6px 12px;
  height: 38px;
  min-width: 280px;
  box-sizing: border-box;
  color: #333;
  margin: 12px 12px 24px 0;
`;

const SelectInput = Styled.select`
  border-radius: 4px;
  border: none;
  box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.15);
  padding: 6px 12px;
  height: 38px;
  min-width: 280px;
  box-sizing: border-box;
  color: #333;
  background: #FFF;
  margin: 12px 12px 24px 0;

  /* @media screen and (max-width: 540px) {
    min-width: auto;
    width: 100%;
  } */
`;

const Label = ({ text, children }) => {
  return (
    <label>
      <div
        style={{ color: '#555', display: 'inline-block', marginRight: '12px' }}
      >
        {text}
      </div>
      {children}
    </label>
  );
};

const StateSelectInput = ({ ...props }) => (
  <SelectInput {...props}>
    <option value="">-- Select a state --</option>
    <option value="AL">Alabama</option>
    <option value="AK">Alaska</option>
    <option value="AZ">Arizona</option>
    <option value="AR">Arkansas</option>
    <option value="CA">California</option>
    <option value="CO">Colorado</option>
    <option value="CT">Connecticut</option>
    <option value="DE">Delaware</option>
    <option value="DC">District Of Columbia</option>
    <option value="FL">Florida</option>
    <option value="GA">Georgia</option>
    <option value="HI">Hawaii</option>
    <option value="ID">Idaho</option>
    <option value="IL">Illinois</option>
    <option value="IN">Indiana</option>
    <option value="IA">Iowa</option>
    <option value="KS">Kansas</option>
    <option value="KY">Kentucky</option>
    <option value="LA">Louisiana</option>
    <option value="ME">Maine</option>
    <option value="MD">Maryland</option>
    <option value="MA">Massachusetts</option>
    <option value="MI">Michigan</option>
    <option value="MN">Minnesota</option>
    <option value="MS">Mississippi</option>
    <option value="MO">Missouri</option>
    <option value="MT">Montana</option>
    <option value="NE">Nebraska</option>
    <option value="NV">Nevada</option>
    <option value="NH">New Hampshire</option>
    <option value="NJ">New Jersey</option>
    <option value="NM">New Mexico</option>
    <option value="NY">New York</option>
    <option value="NC">North Carolina</option>
    <option value="ND">North Dakota</option>
    <option value="OH">Ohio</option>
    <option value="OK">Oklahoma</option>
    <option value="OR">Oregon</option>
    <option value="PA">Pennsylvania</option>
    <option value="RI">Rhode Island</option>
    <option value="SC">South Carolina</option>
    <option value="SD">South Dakota</option>
    <option value="TN">Tennessee</option>
    <option value="TX">Texas</option>
    <option value="UT">Utah</option>
    <option value="VT">Vermont</option>
    <option value="VA">Virginia</option>
    <option value="WA">Washington</option>
    <option value="WV">West Virginia</option>
    <option value="WI">Wisconsin</option>
    <option value="WY">Wyoming</option>
  </SelectInput>
);

const SecondaryButton = Styled.button`
  color: ${({ color }) => (color ? color : '#3273DC')};
  border: none;
  background: transparent;
  transition: all .3s ease;
  cursor: pointer;

  &:hover {
    color: ${({ hoverColor }) => (hoverColor ? hoverColor : '#5293FC')};
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
`;

export {
  TextareaInput,
  PrimaryButton,
  TextInput,
  SelectInput,
  StateSelectInput,
  Label,
  SecondaryButton,
};
