import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class MouseTracker extends Component {
  state = {
    mouseX: null,
    mouseY: null,
  };

  static propTypes = {
    // Callback fired when mouse moves.
    onMouseMove: PropTypes.func,
    children: PropTypes.func,
  };

  static defaultProps = {
    children: () => {},
  };

  componentDidMount() {
    window.addEventListener('mousemove', this.handleMouseMove);
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleMouseMove);
  }

  handleMouseMove = ev => {
    const x = ev.x;
    const y = ev.y;

    this.setState(
      {
        mouseX: x,
        mouseY: y,
      },
      () => {
        this.props.onMouseMove &&
          this.props.onMouseMove({
            mouseX: this.state.mouseX,
            mouseY: this.state.mouseY,
            time: new Date(),
          });
      }
    );
  };

  render() {
    return (
      <Fragment>
        {this.props.children({
          mouseMoveX: this.state.mouseX,
          mouseMoveY: this.state.mouseY,
        })}
      </Fragment>
    );
  }
}

export default MouseTracker;
