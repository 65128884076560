import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import MouseTracker from '../components/mouseTracker';
import ClickTracker from '../components/clickTracker';
import ScrollTracker from '../components/scrollTracker';

class IsIdle extends Component {
  state = {
    isIdle: false,
  };

  static propTypes = {
    // Milliseconds of inactivity after which user is considered idle.
    timeout: PropTypes.number,
    // Callback fired when idle status changes.
    onIdleStatusChange: PropTypes.func,
  };

  static defaultProps = {
    // Five minutes
    timeout: 5 * 60 * 1000,
  };

  componentDidMount() {
    this.timeoutHandle = window.setTimeout(
      this.handleTimeout,
      this.props.timeout
    );
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeoutHandle);
  }

  onActivity = () => {
    window.clearTimeout(this.timeoutHandle);
    if (this.state.isIdle === true) {
      this.setState({ isIdle: false }, () => {
        this.props.onIdleStatusChange &&
          this.props.onIdleStatusChange(this.state.isIdle);
      });
    }
    this.timeoutHandle = window.setTimeout(
      this.handleTimeout,
      this.props.timeout
    );
  };

  handleTimeout = () => {
    this.setState({ isIdle: true }, () => {
      this.props.onIdleStatusChange &&
        this.props.onIdleStatusChange(this.state.isIdle);
    });
  };

  render() {
    return (
      <Fragment>
        <MouseTracker onMouseMove={this.onActivity} />
        <ClickTracker onClick={this.onActivity} />
        <ScrollTracker onWindowScroll={this.onActivity} />
      </Fragment>
    );
  }
}

export default IsIdle;
