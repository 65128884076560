import React from 'react';
import PropTypes from 'prop-types';

import Styled from 'styled-components';
import classnames from 'classnames';

/**
 * Must be a direct descendant of OptionGroup.
 *
 * @param {Object} props
 */
const OptionButton = ({
  children,
  name,
  multiple,
  selected,
  onClick,
  ...rest
}) => {
  const classes = classnames('primespot-ui__big-form__option-button', {
    selected,
  });

  return (
    <Wrapper
      className={classes}
      onClick={onClick}
      name={name}
      data-multiple={multiple}
      {...rest}
    >
      {children}
    </Wrapper>
  );
};

OptionButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  value: PropTypes.string.isRequired,
  selected: PropTypes.bool,
};

OptionButton.defaultProps = {
  selected: false,
  onClick: () => {},
};

const Wrapper = Styled.div`
  border-radius: 4px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, .3);
  padding: 12px;
  display: inline-block;
  margin-right: 24px;
  margin-bottom: 24px;
  cursor: default;
  transition: all .3s ease;

  &.selected {
    background-color: hsl(141,71%,48%);
    color: white;
  }

  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, .4);
    transform: translateY(-1px);
  }
`;

export default OptionButton;
