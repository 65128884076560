import React from 'react';
import { Link } from 'gatsby';

import Styled from 'styled-components';

import DashboardIcon from '../images/dashboard-icon.svg';
import DashboardIconWhite from '../images/dashboard-icon-white.svg';
import ChatIcon from '../images/speech-bubbles.png';
import VisitorsIcon from '../images/multiple-users.png';
// import UsersIcon from '../images/users-icon.svg';
// import UsersIconWhite from '../images/users-icon-white.svg';
// import LeadsIcon from '../images/leads-icon.svg';
// import LeadsIconWhite from '../images/leads-icon-white.svg';

import SVG from '../primespot-ui/components/svg';

import { getGravatarImage, getGravatarUrl } from '../util/gravatar';

const Sidebar = ({ location: { pathname }, user, miniSidebar }) => {
  return (
    <Wrapper miniSidebar={miniSidebar}>
      {user && <UserSection user={user} miniSidebar={miniSidebar} />}
      <ul>
        <li className={pathname === '/backend' ? 'active' : ''}>
          <Link to="/backend">
            <img
              className="sidebar__icon sidebar__icon--main"
              src={DashboardIcon}
              alt=""
              style={{ height: '22px', width: '24px' }}
            />
            <img
              className="sidebar__icon sidebar__icon--white"
              src={DashboardIconWhite}
              alt=""
              style={{ height: '22px', width: '24px' }}
            />
            Dashboard
          </Link>
        </li>
        <li>
          <Link to="/backend/chats">
            <img
              className="sidebar__icon sidebar__icon--white"
              src={ChatIcon}
              alt=""
              style={{ height: '22px', width: '24px' }}
            />
            Chats
          </Link>
        </li>
        <li>
          <Link to="/backend/visitors">
            <img
              className="sidebar__icon sidebar__icon--white"
              src={VisitorsIcon}
              alt=""
              style={{ height: '22px', width: '24px' }}
            />
            Visitors
          </Link>
        </li>
        <li>
          <Link
            to="/backend/giveaway-contacts"
            style={{ whiteSpace: 'nowrap' }}
          >
            {/*<img
              className="sidebar__icon sidebar__icon--white"
              src={VisitorsIcon}
              alt=""
              style={{ height: '22px', width: '24px' }}
            />*/}
            <div
              style={{
                display: 'inline-block',
                height: '22px',
                width: '24px',
                fill: 'white',
                marginRight: '24px',
              }}
            >
              <SVG
                image="inbox-full"
                className="sidebar__icon sidebar__icon--white"
              />
            </div>
            Giveaway Contacts
          </Link>
        </li>
        {/*<li className={pathname === '/backend/users' ? 'active' : ''}>
          <Link to="/backend/users">
            <img
              className="sidebar__icon sidebar__icon--main"
              src={UsersIcon}
              alt=""
            />
            <img
              className="sidebar__icon sidebar__icon--white"
              src={UsersIconWhite}
              alt=""
            />
            Users
          </Link>
        </li>
        <li className={pathname === '/backend/leads' ? 'active' : ''}>
          <Link to="/backend/leads">
            <img
              className="sidebar__icon sidebar__icon--main"
              src={LeadsIcon}
              alt=""
            />
            <img
              className="sidebar__icon sidebar__icon--white"
              src={LeadsIconWhite}
              alt=""
            />
            Leads
          </Link>
  </li>*/}
      </ul>
    </Wrapper>
  );
};

const Wrapper = Styled.aside`
  position: fixed;
  height: 100vh;
  width: 240px;
  width: ${({ miniSidebar }) => (miniSidebar ? '60px' : '240px')};
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgb(50, 50, 50);
  background-color: #12131A;
  background-color: #00897B;
  color: #8287a9;
  z-index: 9;
  padding: 80px 0 0 0;
  box-sizing: border-box;
  overflow-x: hidden;
  transition: all .3s ease;

  &:hover {
    width: 240px;
  }

  @media screen and (max-width: 820px) {
    display: none;
  }

  .sidebar__icon {
    height: 24px;
    width: 24px;
    margin-right: 24px;
  }

  .sidebar__icon--white {
    display: none;
  }

  .sidebar__icon--main {
    display: none;
  }

  .sidebar__icon--white {
    display: block;
  }

  a {
    color: #8287a9;
    color: white;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 18px 12px 18px 18px;
  }

  ul {
    list-style-type: none;
    padding-left: 0;

    li {
      display: flex;
      align-items: center;
      transition: all .2s ease;

      &.active, &:hover {
        background-color: #01D1B2;

        & a {
          color: white;
        }

        & .sidebar__icon--main {
          display: none;
        }

        & .sidebar__icon--white {
          display: block;
        }
      }
    }
  }
`;

const UserSection = ({ user, miniSidebar }) => {
  return (
    <UserSectionWrapper miniSidebar={miniSidebar}>
      <Link to="/backend/profile" aria-label="Profile">
        <div className="sidebar__user-section__image">
          {user &&
            user.email &&
            getGravatarImage(getGravatarUrl(user.email, 40))}
        </div>
        <div className="sidebar__user-section__name">
          {user && `${user.firstName} ${user.lastName}`}
        </div>
      </Link>
    </UserSectionWrapper>
  );
};

const UserSectionWrapper = Styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  box-sizing: border-box;
  padding-left: 9px;
  padding-right: 12px;
  padding: 12px 12px 12px 9px;
  margin-bottom: 36px;
  width: 240px;
  flex-wrap: nowrap;
  overflow: hidden;
  transition: all .3s ease;

  &:active, &:hover {
    background-color: #01D1B2;
  }

  a {
    padding: 0;
  }
  
  .sidebar__user-section__image {
    margin-right: 12px;
    border-radius: 50%;
    overflow: hidden;
    width: 40px;
    height: 40px;
  }

  .sidebar__user-section__name {
    color: #DDD;
    display: block;
  }
`;

export default Sidebar;
