import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Styled from 'styled-components';

import Header from '../components/backendHeader';
import Sidebar from '../components/backendSidebar';
import UserSidebar from '../components/userSidebar';
import NotificationProvider from '../components/notificationProvider';

import matchMediaCheck from '../util/matchMediaCheck';
import config from '../config';

class Layout extends Component {
  state = {
    desktopScreen: null,
  };

  static propTypes = {
    children: PropTypes.node,
    miniSidebar: PropTypes.bool,
    noPadding: PropTypes.bool,
    user: PropTypes.object,
    login: PropTypes.func,
    getEmailToken: PropTypes.func,
    logout: PropTypes.func,
    growl: PropTypes.func,
  };

  componentDidMount() {
    this._isMounted = true;
    this.MatchMediaCheck = matchMediaCheck('1080px');
    this._isMounted &&
      this.setState({ desktopScreen: !this.MatchMediaCheck.matches() });
    this.MatchMediaCheck.registerResizeListener(this.checkDesktopScreen);
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.MatchMediaCheck.removeResizeListener(this.checkDesktopScreen);
  }

  checkDesktopScreen = () => {
    this.setState({ desktopScreen: !this.MatchMediaCheck.matches() });
  };

  render() {
    if (!config.backendActive) {
      return <div />;
    }

    const {
      location,
      children,
      user,
      login,
      getEmailToken,
      logout,
      growl,
      miniSidebar,
      noPadding,
    } = this.props;

    return (
      <Wrapper miniSidebar={miniSidebar} noPadding={noPadding}>
        <Header
          user={user}
          login={login}
          getEmailToken={getEmailToken}
          logout={logout}
          growl={growl}
        />

        <Sidebar location={location} user={user} miniSidebar={miniSidebar} />

        {user &&
          ['sales', 'admin', 'super admin'].includes(user.role) &&
          this.state.desktopScreen !== null && (
            <UserSidebar
              defaultOpen={
                location.pathname === '/backend' &&
                this.state.desktopScreen === true
                  ? true
                  : false
              }
            />
          )}

        <div className="layout__main-content">{children}</div>
        <NotificationProvider />
      </Wrapper>
    );
  }
}

const Wrapper = Styled.div`
  font-family: lato, sans-serif;
  font-size: 18px;
  padding-top: 50px;
  padding-left: 260px;
  padding-left: ${({ miniSidebar }) => (miniSidebar ? '60px' : '260px')};
  background-color: #F2F4F8;
  min-height: calc(100vh - 50px);

  button, input, select, textarea {
    font-size: 16px;
  }

  @media screen and (max-width: 820px) {
    padding-left: 0;
  }

  .layout__main-content {
    padding: ${({ noPadding }) => (noPadding ? '0' : '24px')};
  }
`;

export default Layout;
