import React from 'react';
import PropTypes from 'prop-types';

import Styled from 'styled-components';

const SlidingModal = ({ children, onClose, visible, background, header }) => {
  return (
    <Wrapper visible={visible}>
      <ModalContentWrapper
        background={background}
        className="sliding-modal__modal-content"
        visible={visible}
      >
        <div className="sliding-modal__header">
          <span className="sliding-modal__header__text">{header}</span>
          <span className="sliding-modal__close-button" onClick={onClose}>
            &#10005;
          </span>
        </div>

        <div className="sliding-modal__inner-wrapper">{children}</div>
      </ModalContentWrapper>
    </Wrapper>
  );
};

SlidingModal.propTypes = {
  // Modal content
  children: PropTypes.any,
  // Callback called when the close button is clicked.
  onClose: PropTypes.func,
  // Whether or not the modal is visible.
  visible: PropTypes.bool,
  // Change the background color.
  background: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

SlidingModal.defaultProps = {
  onClose: () => {},
  visible: false,
};

const Wrapper = Styled.div`
  position: fixed;
  z-index: 1000;
  box-sizing: border-box;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 !important;
  background-color: rgba(0, 0, 0, .5);
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
  transition: all .2s ease;

  .sliding-modal__header {
    height: 50px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
    display: flex;
    align-items: center;
  }

  .sliding-modal__header * {
    margin: 0;
  }

  .sliding-modal__header__text {
    height: 100%;
    width: calc(100% - 50px);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 24px;
    font-size: 24px;
  }

  .sliding-modal__close-button {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    font-size: 24px;
  }
`;

const ModalContentWrapper = Styled.div`
  position: absolute;
  overflow-y: auto;
  background: ${({ background }) => (background ? background : '#FFF')};
  border-radius: 0 0 4px 4px;
  padding: 24px;
  padding-top: 42px;
  padding-top: 92px;
  margin: 0 !important;
  z-index: 1001;
  bottom: ${({ visible }) => (visible ? '30vh' : '100vh')};
  left: 0;
  right: 0;
  height: 70vh;
  box-sizing: border-box;
  transition: all .4s ease;


  @media screen and (max-width: 540px) {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border-radius: 0;
  }
`;

export default SlidingModal;
