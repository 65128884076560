import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Styled from 'styled-components';
import classnames from 'classnames';

import Button from '../button';
import SVG from '../../../components/svg';

import pull from 'lodash/pull';

function toggleArrayElement(arr, el) {
  let newArr;
  if (Array.isArray(arr)) {
    newArr = [...arr];
  } else if (arr === '' || arr === undefined || arr === null) {
    newArr = [];
  } else {
    newArr = [arr];
  }

  const index = newArr.indexOf(el);
  if (index === -1) {
    newArr.push(el);
  } else {
    pull(newArr, el);
  }

  return newArr;
}

class BigForm extends Component {
  static propTypes = {
    // Array or object.  If pages is an array, the form will be traversed linearly.
    // If pages is an object, the form will have to be managed through callbacks.
    // In the object format, the object is shaped such that each key is an identifier
    // for a page and the corresponding value is the <Page /> component.
    pages: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
    // The key of the pages object to show.
    activePage: PropTypes.string,
    // Treated as a regular page except the 'next' button will be labeled 'finish'
    finalPage: PropTypes.string,
    // Perhaps unnecessary.
    successPage: PropTypes.string,
    // Perhaps unnecessary.
    errorPage: PropTypes.string,
    // Callback when form is completed.
    onSubmit: PropTypes.func.isRequired,
    // Callback for when the 'next' button is clicked.
    onNextClick: PropTypes.func,
    // Callback for when the 'back' button is clicked.
    onBackClick: PropTypes.func,
    // The default form values.
    initialValues: PropTypes.object.isRequired,
    // true (default) shows the form controls and false hides them.
    showControls: PropTypes.bool,
  };

  static defaultProps = {
    onNextClick: () => {},
    onBackClick: () => {},
    showControls: true,
    activePage: null,
  };

  state = { values: this.props.initialValues, activePage: null };

  componentDidMount() {
    this._isMounted = true;
    if (Array.isArray(this.props.pages)) {
      this.setState({ activePage: 0 });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  showActivePage = () => {
    let activePage =
      this.state.activePage !== null
        ? this.props.pages[this.state.activePage]
        : this.props.pages[this.props.activePage];

    const classes = classnames('big-form__page', activePage.props.className);

    return React.cloneElement(activePage, {
      ...activePage.props,
      onChange: this.onChange,
      values: this.state.values,
      className: classes,
    });
  };

  onChange = ev => {
    const target = ev.target;
    const oldValues = this.state.values;
    if (target.type === 'textarea' || target.type === 'text') {
      this._isMounted &&
        this.setState({
          values: Object.assign({}, oldValues, {
            [target.name]: target.value,
          }),
        });
    } else if (
      target.tagName === 'DIV' &&
      target.getAttribute('name') &&
      target.getAttribute('value')
    ) {
      this._isMounted &&
        this.setState({
          values: Object.assign({}, oldValues, {
            [target.getAttribute('name')]:
              target.getAttribute('data-multiple') === 'true'
                ? toggleArrayElement(
                    oldValues[target.getAttribute('name')],
                    target.getAttribute('value')
                  )
                : target.getAttribute('value'),
          }),
        });
    }
  };

  onNextClick = () => {
    if (this.state.activePage !== null) {
      // Linear form.
      const pagesLength = this.props.pages.length;
      if (this.state.activePage === pagesLength - 1) {
        this.props.onSubmit({
          action: 'finish',
          values: this.state.values,
        });
      } else {
        this.props.onNextClick &&
          this.props.onNextClick({
            action: 'next',
            values: this.state.values,
            activePage: this.state.activePage,
          });

        this.setState(prevState => ({ activePage: prevState.activePage + 1 }));
      }
    } else {
      // Non-linear form.
      if (
        this.props.finalPage === this.props.activePage &&
        this.props.onSubmit
      ) {
        this.props.onSubmit({
          action: 'finish',
          values: this.state.values,
        });
      } else if (this.props.finalPage !== this.props.activePage) {
        this.props.onNextClick &&
          this.props.onNextClick({
            action: 'next',
            values: this.state.values,
            activePage: this.props.activePage,
          });
      }
    }
  };

  onBackClick = () => {
    if (this.state.activePage !== null) {
      // Linear form
      if (this.state.activePage > 0) {
        this.props.onBackClick &&
          this.props.onBackClick({
            action: 'back',
            values: this.state.values,
            activePage: this.state.activePage,
          });

        this.setState(prevState => ({ activePage: prevState.activePage - 1 }));
      }
    } else {
      // Non-linear form
      this.props.onBackClick &&
        this.props.onBackClick({
          action: 'back',
          values: this.state.values,
          activePage: this.props.activePage,
        });
    }
  };

  getActivePage = () => {
    return this.state.activePage !== null
      ? this.props.pages[this.state.activePage]
      : this.props.pages[this.props.activePage];
  };

  render() {
    if (this.state.activePage === null && !this.props.activePage) {
      return <div />;
    }

    let continueText;
    if (this.getActivePage && this.getActivePage().props.continueText) {
      continueText = this.getActivePage().props.continueText;
    } else {
      continueText =
        this.props.finalPage === this.props.activePage ? 'Finish' : 'Continue';
    }

    return (
      <Wrapper>
        {this.showActivePage()}
        {this.props.showControls && (
          <Fragment>
            <hr />
            <div className="primespot-ui__big-form__controls">
              <Button tertiary onClick={this.onBackClick}>
                <SVG image="cheveron-left" />
                Back
              </Button>
              <Button primary onClick={this.onNextClick}>
                {continueText}
              </Button>
            </div>
          </Fragment>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = Styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  * {
    box-sizing: border-box;
  }

  hr {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .primespot-ui__big-form__controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .svg__cheveron-left {
      fill: hsl(217,71%,53%);
      width: 24px;
      height: 24px;
      margin-right: 3px;
    }
  }
`;

export default BigForm;
