import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import Styled from 'styled-components';
import { Formik, Form, ErrorMessage } from 'formik';

/*
 * Form library that accepts a schema object and automatically creates
 * a form with included REST POST and PATCH requests.
 * This library aims to make simple form creation as close to automatic
 * as it can get.
 *
 * Version: 0.0.1
 *
 * TODO:
 * - [ ] Implement error message.
 * - [ ] Implement validation.
 *
 * Schema example
 * {
 *   name: 'Field name.  Links to the database name.',
 *   label: 'Optional field that sets the label.  Will be the name field if label isn't supplied.,
 *   id: 'Optional ID that will be used as an HTML ID.'
 *   dataType: 'Type of field such as string, text, option, etc.',
 *   type: 'Optional type attribute to be applied to the field such as email or password.',
 *   group: 'Used to group fields such as checkboxes together.  Will submit all checked values as one object instead of as individual booleans.',
 *   placeholder: 'Placeholder text',
 *   availableOnCreate: 'Boolean.  Default is true.  Set to false to hide in create forms.',
 *   availableOnEdit: 'Boolean.  Default is true.  Set to false to hide in edit forms.',
 *   validation: { // Validation examples are used below.
 *     minLength: {
 *       value: 6,
 *       message: 'Must be at least six characters long.'
 *     },
 *     required: {
 *       value: true,
 *       message: 'This field is required.'
 *     }
 *   },
 *   options: [{ // Used for select fields.
 *     value: 'The value of the selection.  Will be stored in database.',
 *     label: 'The human-readable label.'
 *   }]
 * }
 *
 * List of dataTypes: (x marks implemented.  All others aren't yet implemented.)
 * - [x] string - Regular input field. *Fields default to this.
 * - [ ] text - Textarea input field
 * - [ ] checkbox - Checkbox
 * - [ ] radio - Radio button.
 * - [x] select - Select box.
 * - [ ] stateSelect - Select box containing list of U.S. states.
 */
class SmartForm extends Component {
  state = {
    loading: true,
  };

  static propTypes = {
    // Schema object.
    schema: PropTypes.array,
    // The ID parameter.  If undefined, this is a create form.  Otherwise, it's an edit form.
    dataId: PropTypes.string,
    // The URL to call.
    apiUrl: PropTypes.string,
    // Headers to pass with REST requests including authorization headers.
    headers: PropTypes.object,
    // False to disable saving changes.  Defaults to true.
    enableSave: PropTypes.bool,
    // The label for the submit button.
    submitButtonText: PropTypes.string,
    // Function to display a notification.
    notify: PropTypes.func,
    // Callback when data changes.
    onDataChange: PropTypes.func,
    // Set to false to remove the delete button.
    enableDelete: PropTypes.bool,
    // Callback when a deletion is successful.
    onDelete: PropTypes.func,
  };

  static defaultProps = {
    submitButtonText: 'Submit',
    enableDelete: true,
    enableSave: true,
  };

  componentDidMount() {
    this.data = {};
    this.props.schema.forEach(s => {
      if (s.dataType === 'select' && s.options && s.options.length > 0) {
        const selected = s.options.find(el => el.selected === true);
        if (selected) {
          this.data[s.name] = selected.value;
        }
      } else {
        this.data[s.name] = '';
      }
    });

    if (this.props.dataId) {
      // Fetch data from the API.  This is an edit operation.
      this.populateData();
    } else {
      // This is a create operation.

      this.setState({ loading: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.dataId !== prevProps.dataId) {
      this.populateData();
    }
  }

  populateData = () => {
    axios
      .get(`${this.props.apiUrl}/${this.props.dataId}`, {
        headers: this.props.headers,
      })
      .then(res => {
        if (res) {
          // this.data = res.data;
          this.data = Object.assign({}, this.data, res.data);
          this.setState({ loading: false });
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  handleDelete = ev => {
    ev.preventDefault();
    if (!this.props.dataId) {
      return;
    }

    if (!window.confirm('Are you sure you want to delete this data?')) {
      return;
    }

    axios
      .delete(`${this.props.apiUrl}/${this.props.dataId}`, {
        headers: this.props.headers,
      })
      .then(res => {
        if (res) {
          if (this.props.onDataChange) {
            this.props.onDataChange(res);
          }
          if (this.props.notify) {
            this.props.notify({
              message: 'Update successful.',
              type: 'success',
            });
          }
          if (this.props.onDelete) {
            this.props.onDelete();
          }
        }
      })
      .catch(err => {
        console.error(err);
        this.props.notify('Something went wrong with your request.', 'danger');
      });
  };

  getField = (schema, values, errors, touched, handleChange, handleBlur) => {
    if (schema.dataType === 'select') {
      return (
        <SelectField
          value={values[schema.name]}
          name={schema.name}
          id={schema.id}
          placeholder={schema.placeholder}
          onChange={e => {
            if (this.props.enableSave) {
              handleChange(e);
            }
          }}
          onBlur={handleBlur}
        >
          {schema.options &&
            schema.options.map(opt => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
        </SelectField>
      );
    }

    return (
      <InputField
        name={schema.name}
        id={schema.id}
        value={values[schema.name]}
        onChange={e => {
          if (this.props.enableSave) {
            handleChange(e);
          }
        }}
        onBlur={handleBlur}
        type={schema.type}
        placeholder={schema.placeholder}
      />
    );
  };

  render() {
    if (!this.data && this.state.loading) {
      return <div />;
    }
    const initialValues = this.data;
    return (
      <Wrapper>
        {this.state.loading ? (
          <Fragment>
            <h3>Form data loading...</h3>
          </Fragment>
        ) : (
          <Fragment>
            <Formik
              initialValues={initialValues}
              onSubmit={(values, { setSubmitting }) => {
                if (this.props.dataId) {
                  // Patch operation
                  axios
                    .patch(
                      `${this.props.apiUrl}/${this.props.dataId}`,
                      values,
                      {
                        headers: this.props.headers,
                      }
                    )
                    .then(res => {
                      if (res) {
                        if (this.props.onDataChange) {
                          this.props.onDataChange(res);
                        }
                        if (this.props.notify) {
                          this.props.notify({
                            message: 'Update successful.',
                            type: 'success',
                          });
                        }
                      }
                    })
                    .catch(err => {
                      console.error(err);
                      if (this.props.notify) {
                        this.props.notify({
                          message: 'Update failed.',
                          type: 'danger',
                        });
                      }
                    })
                    .finally(() => {
                      setSubmitting(false);
                    });
                } else {
                  // Post operation
                  axios
                    .post(this.props.apiUrl, values, {
                      headers: this.props.headers,
                    })
                    .then(res => {
                      if (this.props.onDataChange) {
                        this.props.onDataChange(res);
                      }
                      if (this.props.notify) {
                        this.props.notify({
                          message: 'Creation successful.',
                          type: 'success',
                        });
                      }
                    })
                    .catch(err => {
                      console.error(err);
                      if (this.props.notify) {
                        this.props.notify({
                          message: 'Creation failed.',
                          type: 'danger',
                        });
                      }
                    })
                    .finally(() => {
                      setSubmitting(false);
                    });
                }
              }}
            >
              {({
                isSubmitting,
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
              }) => (
                <Form>
                  {this.props.schema &&
                    this.props.schema
                      .filter(field => {
                        if (this.props.dataId) {
                          // Edit action
                          return field.availableOnEdit !== false;
                        } else {
                          // Create action
                          return field.availableOnCreate !== false;
                        }
                      })
                      .map((s, index) => {
                        return (
                          <div key={index} className="smart-form__field">
                            <label>
                              <div className="smart-form__label">
                                {s.label || s.name}
                              </div>
                              {this.getField(
                                s,
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur
                              )}
                            </label>
                            <ErrorMessage
                              className="smart-form__error-message"
                              component="div"
                              name={s.name}
                            />
                          </div>
                        );
                      })}
                  {this.props.enableSave && (
                    <button
                      className="smart-form__submit-button"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {this.props.submitButtonText}
                    </button>
                  )}
                  {this.props.dataId && this.props.enableDelete && (
                    <a
                      href="/smart-form/delete"
                      className="smart-form__delete-button"
                      onClick={this.handleDelete}
                    >
                      Delete
                    </a>
                  )}
                </Form>
              )}
            </Formik>
          </Fragment>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = Styled.div`
  .smart-form__text-input-field {
    border-radius: 4px;
    border: none;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.15);
    padding: 6px 12px;
    height: 38px;
    min-width: 280px;
    box-sizing: border-box;
    color: #333;
  }

  .smart-form__field {
    margin-bottom: 18px;
  }

  .smart-form__label {
    margin-bottom: 6px;
  }

  .smart-form__error-message {
    color: red;
  }

  .smart-form__submit-button {
    background-color: #01D1B2;
    color: white;
    padding: 12px 24px;
    margin-bottom: 12px;
    border: none;
    cursor: pointer;
    transition: all .3s ease;
    outline: none;

    &:not(:disabled):hover {
      background-color: #21E1D2;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  .smart-form__delete-button {
    color: #FF3860;
    background: none;
    border: none;
    cursor: pointer;
    transition: all .3s ease;
    outline: none;
    margin-left: 24px;
    text-decoration: none;
  }
`;

const InputField = ({ ...props }) => {
  return (
    <div>
      <input className="smart-form__text-input-field" {...props} />
    </div>
  );
};

const SelectField = ({ children, ...props }) => {
  return (
    <SelectFieldWrapper>
      <select {...props}>{children}</select>
    </SelectFieldWrapper>
  );
};

const SelectFieldWrapper = Styled.div`
  select {
    border-radius: 4px;
    border: none;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.15);
    padding: 6px 12px;
    height: 38px;
    min-width: 280px;
    box-sizing: border-box;
    color: #333;
    background: #FFF;
  }
`;

export default SmartForm;
