import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Styled from 'styled-components';

class Popover extends Component {
  static propTypes = {
    // True to display the popover.
    isOpen: PropTypes.bool.isRequired,
    // The content of the popover.
    content: PropTypes.node.isRequired,
    // Popover trigger (activator).  Click to activate.
    trigger: PropTypes.element.isRequired,
    // Callback called when a click is registered outside of the popover.
    onOutsideClick: PropTypes.func,
    // Location of the popover relative to the trigger.
    placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
    // Alignment of the popover relative to the activator.
    align: PropTypes.oneOf(['start', 'center', 'end']),
    // Distance between popover content and trigger.
    gap: PropTypes.string,
    // Custom class to add to the content for styling.
    contentClass: PropTypes.string,
    // True to include an arrow pointing to the trigger.
    showArrow: PropTypes.bool,
    // Custom class to add to the arrow for styling.
    arrowClass: PropTypes.string,
  };

  static defaultProps = {
    placement: 'bottom',
    align: 'center',
    gap: '24px',
    contentClass: 'ps__popover__content',
    showArrow: false,
    arrowClass: 'ps__popover__content__arrow',
  };

  constructor(props) {
    super(props);

    this.triggerRef = React.createRef();
    this.contentRef = React.createRef();
  }

  componentDidMount() {
    this.props.onOutsideClick &&
      window.addEventListener('click', this.onWindowClick);
  }

  componentWillUnmount() {
    this.props.onOutsideClick &&
      window.removeEventListener('click', this.onWindowClick);
  }

  onWindowClick = ev => {
    if (!this.triggerRef.current) return;

    if (this.triggerRef.current.contains(ev.target)) {
      return;
    } else {
      this.props.onOutsideClick && this.props.onOutsideClick();
    }
  };

  render() {
    return (
      <Fragment>
        <TriggerWrapper ref={this.triggerRef}>
          {this.props.trigger}
          <ContentWrapper
            ref={this.contentRef}
            isOpen={this.props.isOpen}
            placement={this.props.placement}
            align={this.props.align}
            gap={this.props.gap}
            className={this.props.contentClass}
          >
            <ArrowWrapper
              className={this.props.arrowClass}
              showArrow={this.props.showArrow}
              placement={this.props.placement}
              align={this.props.align}
            >
              ▲
            </ArrowWrapper>
            {this.props.content}
          </ContentWrapper>
        </TriggerWrapper>
      </Fragment>
    );
  }
}

const TriggerWrapper = Styled.div`
  position: relative;
`;

const ContentWrapper = Styled.div`
  position: absolute;
  transition: all .2s ease;
  opacity: ${({ isOpen }) => (isOpen === true ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (isOpen === true ? 'auto' : 'none')};
  top: ${({ placement, align, gap, isOpen }) => {
    if (placement === 'top') {
      return 'auto';
    } else if (placement === 'bottom') {
      return isOpen ? `calc(100% + ${gap})` : `calc(100% + ${gap} + 24px)`;
    } else if (placement === 'left' || placement === 'right') {
      if (align === 'start') {
        return '0';
      } else if (align === 'center') {
        return '50%'; // Also need to translateY(-50%)
      } else if (align === 'end') {
        return 'auto';
      }
    }
  }};
  bottom: ${({ placement, align, gap, isOpen }) => {
    if (placement === 'top') {
      return isOpen ? `calc(100% + ${gap})` : `calc(100% + ${gap} + 24px)`;
    } else if (placement === 'bottom') {
      return 'auto';
    } else if (placement === 'left' || placement === 'right') {
      if (align === 'start') {
        return 'auto';
      } else if (align === 'center') {
        return 'auto'; // Also need to translateY(-50%)
      } else if (align === 'end') {
        return '0';
      }
    }
  }};
  left: ${({ placement, align, gap, isOpen }) => {
    if (placement === 'right') {
      return isOpen ? `calc(100% + ${gap})` : `calc(100% + ${gap} + 24px)`;
    } else if (placement === 'left') {
      return 'auto';
    } else if (placement === 'top' || placement === 'bottom') {
      if (align === 'start') {
        return '0';
      } else if (align === 'center') {
        return '50%'; // Also need translateX(-50%)
      } else if (align === 'end') {
        return 'auto';
      }
    }
  }};
  right: ${({ placement, align, gap, isOpen }) => {
    if (placement === 'right') {
      return 'auto';
    } else if (placement === 'left') {
      return isOpen ? `calc(100% + ${gap})` : `calc(100% + ${gap} + 24px)`;
    } else if (placement === 'top' || placement === 'bottom') {
      if (align === 'start') {
        return 'auto';
      } else if (align === 'center') {
        return 'auto'; // Also need translateX(-50%)
      } else if (align === 'end') {
        return '0';
      }
    }
  }};
  transform: ${({ placement, align }) => {
    if (placement === 'left' || placement === 'right') {
      if (align === 'center') {
        return 'translateY(-50%)';
      }
    } else if (placement === 'top' || placement === 'bottom') {
      if (align === 'center') {
        return 'translateX(-50%)';
      }
    }
  }};
  z-index: 10000;
`;

const ArrowWrapper = Styled.span`
  display: ${({ showArrow }) => (showArrow ? 'block' : 'none')};
  position: absolute;
  transform-origin: 50% 50%;
  width: 16px;
  height: 16px;
  top: ${({ placement, align }) => {
    if (placement === 'top') {
      return 'calc(100% - 2px)';
    } else if (placement === 'bottom') {
      return 'auto';
    } else if (placement === 'left' || placement === 'right') {
      if (align === 'start') {
        return 'auto';
      } else if (align === 'center') {
        return '50%';
      } else if (align === 'end') {
        return '75%';
      }
    }
  }};
  bottom: ${({ placement, align }) => {
    if (placement === 'top') {
      return 'auto';
    } else if (placement === 'bottom') {
      return 'calc(100% - 2px)';
    } else if (placement === 'left' || placement === 'right') {
      if (align === 'start') {
        return '75%';
      } else if (align === 'center') {
        return '50%';
      } else if (align === 'end') {
        return 'auto';
      }
    }
  }};
  left: ${({ placement, align }) => {
    if (placement === 'left') {
      return 'calc(100% - 2px)';
    } else if (placement === 'right') {
      return 'auto';
    } else if (placement === 'top' || placement === 'bottom') {
      if (align === 'start') {
        return 'auto';
      } else if (align === 'center') {
        return '50%';
      } else if (align === 'end') {
        return '75%';
      }
    }
  }};
  right: ${({ placement, align }) => {
    if (placement === 'left') {
      return 'auto';
    } else if (placement === 'right') {
      return 'calc(100% - 2px)';
    } else if (placement === 'top' || placement === 'bottom') {
      if (align === 'start') {
        return '75%';
      } else if (align === 'center') {
        return '50%';
      } else if (align === 'end') {
        return 'auto';
      }
    }
  }};
  transform: ${({ placement, align }) => {
    if (placement === 'top') {
      if (align === 'center') {
        return 'translateX(-50%) rotate(180deg)';
      }

      return 'rotate(180deg)';
    } else if (placement === 'bottom') {
      if (align === 'center') {
        return 'translateX(-50%) rotate(0deg)';
      }

      return 'rotate(0deg)';
    } else if (placement === 'left') {
      if (align === 'center') {
        return 'translateY(-50%) rotate(90deg)';
      }

      return 'rotate(90deg)';
    } else if (placement === 'right') {
      if (align === 'center') {
        return 'translateY(-50%) rotate(-90deg)';
      }

      return 'rotate(-90deg)';
    }
  }};
`;

export default Popover;
