import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  login,
  logout,
  tokenExists,
  getEmailToken,
} from '../redux/actions/authentication/actionCreators';

import { growl } from '../redux/actions/notifications/actionCreators';

import {
  allow,
  isAllowed,
} from '../redux/actions/authorization/actionCreators';

import Styled, { ThemeProvider } from 'styled-components';

import io from 'socket.io-client';
import distanceInWordsStrict from 'date-fns/distance_in_words_strict';
// import format from 'date-fns/format';

import Notifications from '../primespot-ui/systems/notifications';
import Gravatar from '../primespot-ui/components/gravatar';

import theme from '../styles/theme';
import config from '../config';

class NotificationWidget extends Component {
  state = {
    notifications: [],
  };

  componentDidMount() {
    this._isMounted = true;
    if (this.props.user && this.props.token) {
      this.connectToServer();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.user && this.props.user) ||
      (!prevProps.token && this.props.token)
    ) {
      this.connectToServer();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  connectToServer = () => {
    this.notificationsSocket = io(`${config.backendServer}/notifications`);
    this.conversationsSocket = io(`${config.backendServer}/conversations`);

    this.notificationsSocket.emit(
      'notifications.subscribe',
      {
        token: this.props.token,
      },
      err => {
        if (err) {
          console.error(err);
        }
      }
    );

    this.conversationsSocket.emit(
      'conversations.subscribe',
      {
        token: this.props.token,
      },
      err => {
        if (err) {
          console.error(err);
        }
      }
    );

    this.notificationsSocket.on('leads.new', data => {
      const newNotification = {
        id: data.id,
        data: (
          <div className="notification-widget__notification--new-lead">
            <Gravatar email={data.email} size="36" />
            <div className="notification-widget__notification--right-side">
              <p className="notification-widget__notification--message">
                <strong>{data.name}</strong> was added as a new lead.
              </p>
              <p className="notification-widget__notification--time">{`${distanceInWordsStrict(
                new Date(),
                data.createdAt
              )} ago.`}</p>
            </div>
          </div>
        ),
      };

      const notifications = this.state.notifications.concat(newNotification);
      this._isMounted && this.setState({ notifications });
    });

    // Broken.  Two children with same keys error.
    // this.conversationsSocket.on('conversations.messages.events.new', data => {
    //   const newNotification = {
    //     id: data.messages[data.messages.length - 1]._id,
    //     data: (
    //       <div className="notification-widget__notification--new-chat">
    //         <p className="notification-widget__notification--message">
    //           A new message has been sent.
    //         </p>
    //         <p className="notification-widget__notification--time">
    //           {format(new Date(), 'h:mm:ss A')}
    //         </p>
    //       </div>
    //     ),
    //   };

    //   const notifications = this.state.notifications.concat(newNotification);
    //   this._isMounted && this.setState({ notifications });
    // });
  };

  onNotificationClick = notification => {
    const newNotifications = this.state.notifications.filter(
      n => n.id !== notification.id
    );
    this.setState({ notifications: newNotifications });
  };

  onClearNotificationsClick = () => {
    this.setState({ notifications: [] });
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Wrapper>
          <Notifications
            notifications={this.state.notifications}
            onNotificationClick={this.onNotificationClick}
            onClearNotificationsClick={this.onClearNotificationsClick}
          />
        </Wrapper>
      </ThemeProvider>
    );
  }
}

const Wrapper = Styled.div`
  .notification-widget__notification--new-lead {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 240px;

    img {
      width: 36px;
      height: 36px;
    }
  }

  .notification-widget__notification--right-side {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
  }

  .notification-widget__notification--message {
    margin: 0;
  }

  .notification-widget__notification--time {
    margin: 12px 0 0 0;
    font-size: 80%;
    color: ${({ theme }) => theme.primary.colors.gray.base};
  }
`;

const mapStateToProps = state => {
  return {
    user: state.authentication.user,
    token: state.authentication.token,
    getToken: () => state.authentication.token,
    isLoading: state.authentication.isLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: ({ email, password, token }) =>
      dispatch(login({ email, password, token })),
    logout: () => dispatch(logout()),
    tokenExists: () => dispatch(tokenExists()),
    getEmailToken: () => dispatch(getEmailToken()),
    growl: ({ message, type }) => dispatch(growl({ message, type })),
    allow: (roles, resources, actions) =>
      dispatch(allow(roles, resources, actions)),
    isAllowed: (role, resources, actions) =>
      dispatch(isAllowed(role, resources, actions)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationWidget);
