import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class ScrollTracker extends Component {
  state = {
    windowX: null,
    windowY: null,
  };

  static propTypes = {
    // Callback fired when a scroll event occurs.
    onWindowScroll: PropTypes.func,
    children: PropTypes.func,
  };

  static defaultProps = {
    children: () => {},
  };

  componentDidMount() {
    this._isMounted = true;
    this._isMounted &&
      this.setState({ windowX: window.scrollX, windowY: window.scrollY });
    window.addEventListener('scroll', this.handleWindowScroll);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('scroll', this.handleWindowScroll);
  }

  handleWindowScroll = () => {
    this._isMounted &&
      this.setState(
        { windowX: window.scrollX, windowY: window.scrollY },
        () => {
          this.props.onWindowScroll &&
            this.props.onWindowScroll({
              windowX: this.state.windowX,
              windowY: this.state.windowY,
              time: new Date(),
            });
        }
      );
  };

  render() {
    return (
      <Fragment>
        {this.props.children({
          windowScrolledX: this.state.windowX,
          windowScrolledY: this.state.windowY,
        })}
      </Fragment>
    );
  }
}

export default ScrollTracker;
