import React from 'react';
import PropTypes from 'prop-types';

import Styled from 'styled-components';

class Table extends React.Component {
  static propTypes = {
    caption: PropTypes.string,
    headers: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        key: PropTypes.string,
      })
    ),
    rows: PropTypes.arrayOf(PropTypes.object),
    fullWidth: PropTypes.bool,
  };

  static defaultProps = {
    headers: [],
    rows: [],
    fullWidth: true,
  };

  getDataArray = row => {
    const dataArray = [];
    this.props.headers.forEach(header => {
      dataArray.push(row[header.key] || '');
    });
    return dataArray;
  };

  render() {
    return (
      <Wrapper fullWidth={this.props.fullWidth}>
        <table>
          {this.props.caption && <caption>{this.props.caption}</caption>}
          <thead>
            <tr>
              {this.props.headers.map(header => (
                <th key={header.key}>{header.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {this.props.rows.map((row, index) => (
              <tr key={index}>
                {this.getDataArray(row).map((data, index) => (
                  <td key={index}>{data}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Wrapper>
    );
  }
}

const Wrapper = Styled.div`
  table {
    background: #F9F9F9;
    border-spacing: 0;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  }

  caption {
    caption-side: bottom;
    padding: 12px;
    text-align: left;
    font-size: 15px;
  }

  tr {
    
  }

  thead tr {
    background-color: #F2F2F2;
  }

  th {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1.2px;
    text-align: left;
    border-top: 1px solid #DFDFDF;
  }

  td {
    font-size: 16px;
  }

  td, th {
    /* padding-right: 18px; */
    padding: 12px 18px;
    /* display: inline-block; */
    border-bottom: 1px solid #DFDFDF;
  }
`;

export default Table;
