import React from 'react';
import PropTypes from 'prop-types';

const Page = ({
  children,
  active,
  onChange,
  values,
  continueText,
  ...rest
}) => {
  return <div {...rest}>{children({ active, onChange, values })}</div>;
};

Page.propTypes = {
  active: PropTypes.bool,
  onChange: PropTypes.func,
  values: PropTypes.object,
  // The text label for the 'continue' button.
  continueText: PropTypes.string,
};

export default Page;
