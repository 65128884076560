import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import Styled from 'styled-components';

const Button = ({
  children,
  onClick,
  primary,
  secondary,
  tertiary,
  ...rest
}) => {
  // Check if more than one of these props is true, if so, default to primary only.
  if ([primary, secondary, tertiary].filter(s => s).length > 1) {
    primary = true;
    secondary = false;
    tertiary = false;
  }

  const classes = classnames('primespot-ui__big-form__button', {
    primary: primary || (!secondary && !tertiary),
    secondary,
    tertiary,
  });

  return (
    <Wrapper {...rest} className={classes} onClick={onClick}>
      {children}
    </Wrapper>
  );
};

Button.propTypes = {
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
};

const Wrapper = Styled.button`
  padding: 12px 18px;
  margin-right: 24px;
  margin-bottom: 24px;
  font-size: 16px;
  border-radius: 2px;
  cursor: pointer;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: all .3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .3);
  display: inline-flex;
  align-items: center;

  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, .5);
  }

  &.primary {
    background-color: hsl(217,71%,53%);
    color: white;
    border: 1px solid hsl(217,71%,53%);

    &:hover {
      background-color: hsl(217,71%,43%);
      border: 1px solid hsl(217,71%,43%);
      transform: translateY(-1px);
    }
  }

  &.secondary {
    border: 1px solid hsl(217,71%,53%);
    color: hsl(217,71%,53%);

    &:hover {
      border: 1px solid hsl(217,71%,43%);
      color: hsl(217,71%,43%);
      transform: translateY(-1px);
    }
  }

  &.tertiary {
    color: hsl(217,71%,53%);
    border: none;
    box-shadow: none;

    &:hover {
      color: hsl(217,71%,43%);
    }
  }
`;

export default Button;
