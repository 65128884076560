import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  login,
  logout,
  tokenExists,
  getEmailToken,
} from '../redux/actions/authentication/actionCreators';

import { growl } from '../redux/actions/notifications/actionCreators';

import {
  allow,
  isAllowed,
} from '../redux/actions/authorization/actionCreators';

import Styled, { ThemeProvider } from 'styled-components';
import distanceInWordsStrict from 'date-fns/distance_in_words_strict';
import capitalize from 'lodash/capitalize';

import Gravatar from '../primespot-ui/components/gravatar';
import Toggle from '../primespot-ui/components/toggle';
import Modal from '../primespot-ui/components/modal';
import SVG from '../primespot-ui/components/svg';
import SmartForm from './smartForm';

import theme from '../styles/theme';

import config from '../config';

const usersSchema = [
  {
    name: 'firstName',
    dataType: 'string',
    id: 'users-edit__first-name',
    label: 'First name',
    type: 'text',
    placeholder: 'John',
  },
  {
    name: 'lastName',
    dataType: 'string',
    id: 'users-edit__last-name',
    label: 'Last name',
    type: 'text',
    placeholder: 'Smith',
  },
  {
    name: 'email',
    dataType: 'string',
    id: 'users-edit__email',
    label: 'Email address',
    type: 'email',
    placeholder: 'john.smith@email.com',
  },
  {
    name: 'password',
    dataType: 'string',
    label: 'Password',
    type: 'password',
    placeholder: 'password',
    availableOnEdit: false,
  },
  {
    name: 'phoneNumber',
    dataType: 'string',
    label: 'Phone number',
    type: 'text',
    placeholder: '540-555-1234',
  },
  {
    name: 'role',
    dataType: 'select',
    id: 'users-edit__role',
    label: 'Role',
    placeholder: 'Role',
    availableOnCreate: false,
    options: [
      {
        value: 'user',
        label: 'User',
        selected: true,
      },
      {
        value: 'sales',
        label: 'Sales',
      },
      {
        value: 'admin',
        label: 'Admin',
      },
    ],
  },
];

const UserCard = ({
  user,
  onClose,
  getToken,
  currentUser,
  isAllowed,
  growl,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <OuterWrapper>
          <div className="user-card__card-controls">
            {currentUser && currentUser.role && currentUser.role === 'admin' && (
              <Toggle>
                {({ toggle, toggled }) => (
                  <Fragment>
                    <span
                      className="user-card__card-controls__edit-button"
                      onClick={() => {
                        toggle();
                      }}
                    >
                      <SVG
                        image="edit-pencil"
                        style={{ width: '12px', height: '12px' }}
                      />
                    </span>
                    {toggled && (
                      <Modal onClose={toggle} header="Edit User">
                        <SmartForm
                          schema={usersSchema}
                          dataId={user._id}
                          apiUrl={`${config.backendServer}/users`}
                          headers={{
                            authorization: `Token ${getToken()}`,
                          }}
                          notify={growl}
                          enableDelete={isAllowed(
                            currentUser.role,
                            'users',
                            'delete'
                          )}
                          enableSave={isAllowed(
                            currentUser.role,
                            'users',
                            'update'
                          )}
                        />
                      </Modal>
                    )}
                  </Fragment>
                )}
              </Toggle>
            )}
            <span
              className="user-card__card-controls__close-button"
              onClick={() => {
                onClose();
              }}
            >
              &times;
            </span>
          </div>
          <div className="user-card__meta">
            <Gravatar
              email={user && user.email}
              size={80}
              style={{ borderRadius: '50%', overflow: 'hidden' }}
            />
            <div
              className={`user-card__meta__status ${
                user && user.status && user.status === 'online' ? 'online' : ''
              }${user && user.status && user.status === 'idle' ? 'idle' : ''}`}
            />
            <div className="user-card__meta__name">{`${user &&
              user.firstName} ${user && user.lastName}`}</div>
            <div className="user-card__meta__last-online">
              {user && user.status === 'online' && 'Online now'}
              {user && user.status === 'idle' && 'Idle'}
              {user &&
                user.status === 'offline' &&
                `Last online: ${
                  user && user.lastOnline
                    ? distanceInWordsStrict(new Date(), user.lastOnline)
                    : 'Unknown'
                }`}
            </div>
            <div className="user-card__meta__role">{`${user &&
              capitalize(user.role)}`}</div>
          </div>
          <div className="user-card__bio">
            <dl>
              <span>
                <dt>Email</dt>
                <dd>{user && user.email}</dd>
              </span>
              <span>
                <dt>Phone number</dt>
                <dd>{user && user.phoneNumber}</dd>
              </span>
            </dl>
          </div>
          <div className="user-card__actions">
            <div
              onClick={() => {
                alert('Not yet implemented.');
              }}
            >
              <span>
                <SVG
                  image="envelope"
                  style={{
                    width: '24px',
                    height: '24px',
                  }}
                />
              </span>
              <span>Send a message</span>
            </div>
            <div
              onClick={() => {
                alert('Not yet implemented.');
              }}
            >
              <span>
                <SVG
                  image="conversation"
                  style={{
                    width: '24px',
                    height: '24px',
                  }}
                />
              </span>
              <span>Chat</span>
            </div>
          </div>
        </OuterWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

UserCard.propTypes = {
  user: PropTypes.object,
};

UserCard.defaultProps = {
  user: {},
};

const OuterWrapper = Styled.div`
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, .2);
  position: fixed;
  right: 200px;
  top: 70px;
  display: flex;
  flex-direction: column;
  z-index: 100;
  background: white;
  font-size: 16px;
  width: 320px;

  .user-card__card-controls {
    height: 36px;
  }

  .user-card__card-controls__edit-button {
    position: absolute;
    top: 9px;
    right: 36px;
    cursor: pointer;

    .svg__icon {
      fill: ${({ theme }) => theme.primary.colors.gray.darkest};
      transition: all .3s ease;
    }
  }

  .user-card__card-controls__edit-button:hover {
    .svg__icon {
      fill: ${({ theme }) => theme.primary.colors.gray.base};
    }
  }

  .user-card__card-controls__close-button {
    position: absolute;
    top: 3px;
    right: 6px;
    font-size: 24px;
    cursor: pointer;
    color: ${({ theme }) => theme.primary.colors.gray.darkest};
    transition: all .3s ease;
  }

  .user-card__card-controls__close-button:hover {
    color: ${({ theme }) => theme.primary.colors.gray.base};
  }

  .user-card__meta {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .user-card__meta__status {
    width: 12px;
    height: 12px;
    background-color: ${({ theme }) => theme.primary.colors.gray.base};
    border-radius: 50%;
    border: 3px solid white;
    overflow: hidden;
    margin-top: -9px !important;
    margin-bottom: 12px;
  }

  .user-card__meta__status.online {
    background-color: ${({ theme }) => theme.primary.colors.green.base};
  }

  .user-card__meta__status.idle {
    background-color: ${({ theme }) => theme.primary.colors.yellow.base};
  }

  .user-card__meta__name {
    font-weight: bold;
  }

  .user-card__meta__last-online {
    color: ${({ theme }) => theme.primary.colors.gray.base};
    margin-bottom: 12px;
  }

  .user-card__meta__role {
    color: ${({ theme }) => theme.primary.colors.gray.darkest};
  }

  .user-card__bio {
    span {
      border-bottom: 1px solid ${({ theme }) =>
        theme.primary.colors.gray.lightest};
      padding: 12px;
      display: flex;
      justify-content: space-between;
    }

    span:last-child {
      border-bottom: none;
    }

    dl {
      margin-bottom: 0;
    }

    dt {
      display: inline-block;
      color: ${({ theme }) => theme.primary.colors.gray.base};
    }

    dd {
      display: inline-block;
    }
  }

  .user-card__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border-top: 1px solid ${({ theme }) => theme.primary.colors.gray.lightest};
    border-bottom: 1px solid ${({ theme }) =>
      theme.primary.colors.gray.lightest};

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-right: 1px solid ${({ theme }) =>
        theme.primary.colors.gray.lightest};
      color: ${({ theme }) => theme.primary.colors.gray.darkest};
      transition: all .3s ease;
      cursor: pointer;
      padding: 12px 6px;
      width: 50%;
    }

    div:last-child {
      border-right: none;
    }

    .svg__icon {
      fill: ${({ theme }) => theme.primary.colors.gray.darkest};
      transition: all .3s ease;
    }

    div:hover {
      color: ${({ theme }) => theme.primary.colors.gray.base};
    }

    div:hover .svg__icon {
      fill: ${({ theme }) => theme.primary.colors.gray.base};
    }
  }
`;

const mapStateToProps = state => {
  return {
    currentUser: state.authentication.user,
    token: state.authentication.token,
    getToken: () => state.authentication.token,
    isLoading: state.authentication.isLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: ({ email, password, token }) =>
      dispatch(login({ email, password, token })),
    logout: () => dispatch(logout()),
    tokenExists: () => dispatch(tokenExists()),
    getEmailToken: () => dispatch(getEmailToken()),
    growl: ({ message, type }) => dispatch(growl({ message, type })),
    allow: (roles, resources, actions) =>
      dispatch(allow(roles, resources, actions)),
    isAllowed: (role, resources, actions) =>
      dispatch(isAllowed(role, resources, actions)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserCard);
