import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Styled, { ThemeProvider } from 'styled-components';

import SVG from '../components/svg';
import Popover from '../components/popover';

import theme from '../../styles/theme';

class Notifications extends Component {
  state = {
    // True to display notifications.
    isOpen: false,
  };

  static propTypes = {
    popoverPosition: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
    popoverAlign: PropTypes.oneOf(['start', 'center', 'end']),
    onNotificationClick: PropTypes.func,
    // Array of notifications.  Each element must be an object with a unique id property and a data property that holds the element to show.
    notifications: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        data: PropTypes.element,
      })
    ),
    onClearNotificationsClick: PropTypes.func,
  };

  static defaultProps = {
    popoverPosition: 'bottom',
    popoverAlign: 'end',
    notifications: [],
    onNotificationClick: () => {},
    onClearNotificationsClick: () => {},
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Wrapper active={this.props.notifications.length > 0}>
          <Popover
            isOpen={this.state.isOpen}
            trigger={
              <span
                className="notifications__icon"
                onClick={() => {
                  this.setState(prevState => ({ isOpen: !prevState.isOpen }));
                }}
              >
                <SVG image="notifications" />
              </span>
            }
            placement={this.props.popoverPosition}
            content={
              <div>
                <div className="notifications__content__actions">
                  {this.props.notifications.length > 0 && (
                    <a
                      href="#clear-notifications"
                      onClick={ev => {
                        ev.preventDefault();

                        this.props.onClearNotificationsClick &&
                          this.props.onClearNotificationsClick();

                        this.setState({ isOpen: false });
                      }}
                    >
                      Clear notifications
                    </a>
                  )}
                </div>
                {this.props.notifications.length > 0 ? (
                  <ul>
                    {this.props.notifications.map(n => (
                      <li
                        key={n.id}
                        onClick={() => {
                          this.props.onNotificationClick(n);
                        }}
                      >
                        {n.data}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>There are currently no notifications.</p>
                )}
              </div>
            }
            contentClass="notifications__content"
            align={this.props.popoverAlign}
          />
        </Wrapper>
      </ThemeProvider>
    );
  }
}

const Wrapper = Styled.div`

  .notifications__icon {
    width: 24px;
    height: 24px;
    fill: ${({ theme, active }) =>
      active === true
        ? theme.primary.colors.orange.base
        : theme.primary.colors.gray.base};
    display: block;
    cursor: pointer;
    transition: all .3s ease;
  }

  .notifications__content__actions {
    height: 36px;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 12px;
    
    a {
      text-decoration: none;
      color: ${({ theme }) => theme.primary.colors.gray.darkest};
      transition: all .3s ease;
    }

    a:hover {
      color: ${({ theme }) => theme.primary.colors.gray.base};
    }
  }

  .notifications__content {
    background: white;
    box-shadow: 0 1px 8px rgba(0, 0, 0, .2);
    border-radius: 4px;
    padding: 0 12px 12px 12px;
    min-width: 180px;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    li {
      margin-bottom: 24px;
      cursor: pointer;
    }

    li:last-child {
      margin-bottom: 0;
    }
  }
`;

export default Notifications;
