import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import Styled from 'styled-components';
import io from 'socket.io-client';
import differenceInSeconds from 'date-fns/difference_in_seconds';
import cloneDeep from 'lodash/cloneDeep';

import Layout from '../../layouts/backendLayout';
import Accordion from '../../primespot-ui/components/accordion';
import Table from '../../primespot-ui/components/table';
import SlidingModal from '../../primespot-ui/components/slidingModal';
import Toggle from '../../primespot-ui/components/toggle';
import LoginForm from '../../components/loginForm';

import {
  login,
  logout,
  tokenExists,
  getEmailToken,
} from '../../redux/actions/authentication/actionCreators';

import { growl } from '../../redux/actions/notifications/actionCreators';

import {
  allow,
  isAllowed,
} from '../../redux/actions/authorization/actionCreators';

import config from '../../config';

class VisitorsPage extends Component {
  state = {
    visitors: {},
    messageText: '',
    messageSending: false,
    selectedVisitor: null,
  };

  componentDidMount() {
    this._isMounted = true;
    this.trackerSocket = io(`${config.backendServer}/tracker`);
    this.conversationsSocket = io(`${config.backendServer}/conversations`);
    this.trackerSocket.emit(
      'tracker.subscribe',
      {
        token: this.props.token,
      },
      err => {
        if (err) {
          console.error(err);
        }
      }
    );
    this.trackerSocket.emit(
      'tracker.visitors.find',
      { token: this.props.token },
      (err, visitors) => {
        if (err) {
          console.error(err);
        } else {
          this._isMounted &&
            this.setState({
              visitors: Object.assign({}, this.state.visitors, visitors),
            });
        }
      }
    );

    this.trackerSocket.on('tracker.page.visited', visit => {
      if (this.state.visitors[visit._id] !== undefined) {
        // Visitor already has data.
        const newVisitors = cloneDeep(this.state.visitors);
        newVisitors[visit._id].visits.push(visit);
        this._isMounted && this.setState({ visitors: newVisitors });
      } else {
        // No visitor data.
        this._isMounted &&
          this.setState({
            visitors: Object.assign(
              {},
              { [visit._id]: { status: 'connected', visits: [visit] } }
            ),
          });
      }
    });

    this.trackerSocket.on('tracker.disconnection', visitor => {
      const newVisitors = cloneDeep(this.state.visitors);
      delete newVisitors[visitor.visitorId];
      this._isMounted && this.setState({ visitors: newVisitors });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { user, login, logout, growl, location } = this.props;
    return (
      <Layout
        user={user}
        login={login}
        logout={logout}
        growl={growl}
        location={location}
        miniSidebar
        noPadding
      >
        <Wrapper>
          {user && ['sales', 'admin', 'super admin'].includes(user.role) ? (
            <Toggle>
              {({ toggle: toggleMessageModal, toggled: showMessageModal }) => (
                <Fragment>
                  <button
                    className="button clear-data-button"
                    onClick={() => {
                      this.trackerSocket.emit('tracker.visitors.clear', {
                        token: this.props.token,
                      });

                      this.setState({ visitors: {} });
                    }}
                  >
                    Clear tracking data
                  </button>
                  {Object.keys(this.state.visitors).map(visitor => (
                    <div
                      key={visitor}
                      onClick={() => {
                        this.setState({ selectedVisitor: visitor });
                      }}
                    >
                      <Accordion activator={visitor}>
                        <Fragment>
                          <button
                            className="button"
                            onClick={toggleMessageModal}
                          >
                            Send message
                          </button>
                          <Table
                            headers={[
                              {
                                key: 'location',
                                label: 'Location',
                              },
                              {
                                key: 'timestamp',
                                label: 'Timestamp',
                              },
                            ]}
                            rows={
                              this.state.visitors &&
                              visitor &&
                              this.state.visitors[visitor] &&
                              this.state.visitors[visitor].visits &&
                              this.state.visitors[visitor].visits
                                .sort((a, b) => b.timestamp - a.timestamp)
                                .map(visit => ({
                                  location: visit.location,
                                  timestamp: `${differenceInSeconds(
                                    Date.now(),
                                    visit.timestamp
                                  )} seconds ago`,
                                }))
                            }
                          />
                        </Fragment>
                      </Accordion>
                    </div>
                  ))}
                  <SlidingModal
                    visible={showMessageModal}
                    onClose={toggleMessageModal}
                    header="Send message"
                  >
                    <div>
                      <label>
                        <div style={{ marginBottom: '6px' }}>Message</div>
                        <textarea
                          value={this.state.messageText}
                          onChange={ev => {
                            const value = ev.target.value;
                            this.setState({ messageText: value });
                          }}
                        />
                      </label>
                      <button
                        className="button"
                        disabled={this.state.messageSending}
                        onClick={ev => {
                          ev.preventDefault();

                          this.setState({ messageSending: true });
                          this.conversationsSocket.emit(
                            'conversations.messages.new',
                            {
                              token: this.props.token,
                              message: this.state.messageText,
                              to: this.state.selectedVisitor,
                            },
                            (err, response) => {
                              if (err) {
                                console.error(err);
                                this.props.growl({
                                  type: 'danger',
                                  message:
                                    'Something went wrong sending the message.  Please try again.',
                                });
                              } else {
                                this.props.growl({
                                  type: 'success',
                                  message: 'Your message was sent.',
                                });
                              }

                              this.setState({ messageSending: false });
                            }
                          );
                        }}
                      >
                        {this.state.messageSending ? 'Sending...' : 'Send'}
                      </button>
                    </div>
                  </SlidingModal>
                </Fragment>
              )}
            </Toggle>
          ) : (
            <Fragment>
              <LoginForm />
            </Fragment>
          )}
        </Wrapper>
      </Layout>
    );
  }
}

const Wrapper = Styled.main`
  box-sizing: border-box;
  padding: 24px;

  * {
    box-sizing: border-box;
  }

  .button {
    padding: 12px 24px;
    border: none;
    background: hsl(204, 86%, 53%);
    margin-right: 12px;
    margin-bottom: 12px;
    display: inline-block;
    color: #FFF;
    border-radius: 2px;
    transition: all .3s ease;
    cursor: pointer;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .3);

    &:hover {
      background: hsl(204, 86%, 58%);
      box-shadow: 0 1px 8px rgba(0, 0, 0, .3);
    }

    &:disabled {
      opacity: .5;
    }
  }

  .clear-data-button {
    margin-bottom: 24px;
  }

  textarea {
    display: block;
    height: 120px;
    padding: 12px;
    border-radius: 2px;
    margin-bottom: 12px;
    border: 1px solid rgb(200, 200, 200);
    width: 240px;
  }
`;

const mapStateToProps = state => {
  return {
    user: state.authentication.user,
    token: state.authentication.token,
    getToken: () => state.authentication.token,
    isLoading: state.authentication.isLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: ({ email, password, token }) =>
      dispatch(login({ email, password, token })),
    logout: () => dispatch(logout()),
    tokenExists: () => dispatch(tokenExists()),
    getEmailToken: () => dispatch(getEmailToken()),
    growl: ({ message, type }) => dispatch(growl({ message, type })),
    allow: (roles, resources, actions) =>
      dispatch(allow(roles, resources, actions)),
    isAllowed: (role, resources, actions) =>
      dispatch(isAllowed(role, resources, actions)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VisitorsPage);
