import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Styled from 'styled-components';

class OptionGroup extends Component {
  static propTypes = {
    children: PropTypes.node,
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    onChange: PropTypes.func,
    multiple: PropTypes.bool,
  };

  static defaultProps = {
    multiple: false,
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  wrappedChildren = () => {
    return React.Children.map(this.props.children, child => {
      if (
        child.type.displayName === 'OptionButton' ||
        child.type.name === 'OptionButton'
      ) {
        return React.cloneElement(child, {
          name: this.props.name,
          multiple: this.props.multiple,
          onClick: this.props.onChange,
          selected: this.isValueSelected(child.props.value),
        });
      } else {
        return child;
      }
    });
  };

  isValueSelected = value => {
    if (!this.props.value) {
      return false;
    }

    if (Array.isArray(this.props.value)) {
      return this.props.value.findIndex(v => v === value) !== -1;
    } else {
      return value === this.props.value;
    }
  };

  handleButtonClick = value => {
    console.log(value);
  };

  render() {
    return <Wrapper>{this.wrappedChildren()}</Wrapper>;
  }
}

const Wrapper = Styled.div`

`;

export default OptionGroup;
